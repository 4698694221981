#header-main {
  height: 110px;
  width: 100%;
  position: fixed;
  left: 0;
  background: $black;
  z-index: 999;
  transition: all 0.2s;
  font-family: $primary-font-family;

  @media screen and (max-width: 75rem) {
    left: inherit;
  }

  .header-main-wrapper {
    width: $global-width;
    margin: 0 auto;
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    transition: all 0.2s;

    @media screen and (max-width: $global-width) {
      width: 100%;
    }

    .off-canvas-btn-open {
      display: none;
      width: 36px;
      height: 36px;
      background: url("/assets/img/icon_menu_open.svg") no-repeat;
      background-size: cover;

      &:focus {
        border: 0 none;
        outline: none;
      }

      @include breakpoint(medium down) {
        display: block;
      }
    }

    .logo {
      a {
        display: block;
        height: 48px;
        width: 200px;
        transition: all 0.2s;
        background: url("/assets/img/logo_head_large.svg") no-repeat;
        background-size: cover;

        @include breakpoint(small down) {
          height: 40px;
          width: 40px;
          background-image: url("/assets/img/logo_head_small.svg");
        }
      }
    }

    .navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(medium down) {
        display: none;
      }

      .nav-main {
        ul {
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            margin: 0 0 0 25px;
            padding: 0;
            position: relative;

            @include breakpoint(xlarge down) {
              margin: 0 0 0 20px;
            }

            &:first-child {
              margin-left: 0;
            }

            a {
              display: block;
              height: 80px;
              line-height: 80px;
              font: {
                size: 27px;
                weight: 500;
              }

              @include breakpoint(xlarge down) {
                font: {
                  size: 22px;
                } 
              }

              color: $primary-color;
              transition: all 0.2s;

              &:hover {
                border-bottom: 5px solid $primary-color;
              }
            }

            &.active {
              a {
                border-bottom: 5px solid $primary-color;
              }
            }

            &.has-subnav {
              a {
                &:hover {
                  border: 0 none;
                }
              }
            }

            ul {
              display: none;
              flex-direction: column;
              position: absolute;
              width: 260px;
              height: inherit;
              top: 80px;
              left: -15px;
              background: $black;

              @media screen and (max-width: 1515px) {
                left: inherit;
                right: -15px;
              }

              li {
                transition: all 0.25s;
                width: 100%;
                padding: 0;
                margin: 0;

                &:last-child {
                  border-bottom: 4px solid $primary-color;
                }

                a {
                  padding: 7px 15px;
                  height: inherit;
                  color: $white;
                  border: 0 none;
                  line-height: 24px;
                  font: {
                    size: 20px;
                    weight: 300;
                  }

                  &:hover {
                    border: 0 none;
                  }
                }

                &:first-child {
                  a {
                    padding-top: 14px;
                  }
                }

                &:last-child {
                  a {
                    padding-bottom: 14px;
                  }
                }

                &:hover {
                  transition: all 0.25s;

                  a {
                    color: $primary-color;
                  }
                }
              }

            }

            &:hover {
              ul {
                display: flex;
              }
            }
          }
        }
      }

      /*.search {
        position: relative;

        span {
          display: block;
          width: 36px;
          height: 36px;
          background: url("/assets/img/icon_search_open.svg") no-repeat;
          opacity: .9;
          transition: all 0.2s;

          &.active {
            background: url("/assets/img/icon_search_close.svg") no-repeat;
          }

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }

        .searchinput {
          display: none;
          position: absolute;
          height: 50px;
          width: 220px;
          background: $primary-color;
          top: 58px;
          right: 0;
          justify-content: center;
          align-items: center;

          &.active {
            display: flex;
          }

          form {
            input {
              height: inherit;
              margin: 0;
              border: 0;
              box-shadow: none;
              background: $primary-color;
              color: $white;
              font-weight: 500;

              &::placeholder {
                color: $white;
                font-weight: 500;
              }
            }
          }
        }
      }*/
    }
  }

  &.shrink {
    height: 60px;

    .header-main-wrapper {
      height: 60px;

      .navigation {
        .nav-main {
          ul {
            li {
              a {
                height: 60px;
                line-height: 60px;
              }
            }
          }
        }
      }

      .logo {
        img {
          height: 40px;
          width: auto;
        }
      }

      /*.search {
        .searchinput {
          top: 48px;
        }
      }*/
    }
  }

  @include breakpoint(medium down) {
    height: 60px;
    transition: all 0.2s;

    .header-main-wrapper {
      height: 60px;
      transition: all 0.2s;

      .logo {
        transition: all 0.2s;

        img {
          height: 40px;
          width: auto;
          transition: all 0.2s;
        }
      }
    }
  }
}

.top-bar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 75rem) {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.top-bar {
  height: auto;
  display: flex;
  justify-content: flex-end;
  width: 75rem;
  padding: 0 20px;
  margin: 0 !important;


  ul {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      list-style: none;
      color: white;
      margin-right: 5px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        text-decoration: none;
        color: white;

        &:hover {
          color: $primary-color;
        }

        &.active {
          color: $primary-color;
        }
      }
    }
  }
}