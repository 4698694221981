@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
// @include foundation-accordion-menu;
//@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
// @include foundation-title-bar;
//@include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'slick';
@import 'slick-theme';
@import 'components/header';
@import 'components/offcanvas';
@import 'components/footer';

@font-face {
  font-family: "Impact";
  font-weight: 300;
  src: url("/assets/font/692833/a41e1b81-3206-49c5-bd2a-d2a6727cf783.eot?#iefix");
  src: url("/assets/font/692833/a41e1b81-3206-49c5-bd2a-d2a6727cf783.eot?#iefix") format("eot"), url("/assets/font/692833/eb7104aa-15d7-4190-85bb-6fe4434e602d.woff2") format("woff2"), url("/assets/font/692833/320e46b1-f8bc-48fd-b1c1-026806a249f8.woff") format("woff"), url("/assets/font/692833/29a2eb3d-ef98-429e-a6c3-17742370d5ef.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerNext";
  font-weight: 300;
  src: url("/assets/font/722217/4cef6d85-d22a-4541-b469-da13751862aa.eot?#iefix");
  src: url("/assets/font/722217/4cef6d85-d22a-4541-b469-da13751862aa.eot?#iefix") format("eot"), url("/assets/font/722217/67f049a9-98c0-4488-a4e4-0be6cda6f7de.woff2") format("woff2"), url("/assets/font/722217/d74de079-587d-4049-9cca-50ba02a536f9.woff") format("woff"), url("/assets/font/722217/07749504-e72d-4fc9-a58d-5b853dd51fc7.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerNext";
  font-weight: 500;
  src: url("/assets/font/722169/ce9ebe9b-6684-4783-8760-f3a20a0c52f0.eot?#iefix");
  src: url("/assets/font/722169/ce9ebe9b-6684-4783-8760-f3a20a0c52f0.eot?#iefix") format("eot"), url("/assets/font/722169/983d9248-775f-41ea-ad59-cee5bdbb6147.woff2") format("woff2"), url("/assets/font/722169/5f3f5b96-b77d-497d-a916-d483bc9c6c3f.woff") format("woff"), url("/assets/font/722169/209c5dbd-a0e8-4fdf-ada6-50628360eefd.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerNextCondens";
  font-weight: 300;
  src: url("/assets/font/722190/3aabd8f8-de16-4c1d-94fa-1d85cdeca8b5.eot?#iefix");
  src: url("/assets/font/722190/3aabd8f8-de16-4c1d-94fa-1d85cdeca8b5.eot?#iefix") format("eot"), url("/assets/font/722190/33de3f05-9ad2-4aa8-8305-953d212ed871.woff2") format("woff2"), url("/assets/font/722190/f5206e18-da88-476d-a17e-ac9c580f00c1.woff") format("woff"), url("/assets/font/722190/084bc02f-68ac-47c0-8102-0b1b53e8d4ac.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerNextCondens";
  font-weight: 500;
  src: url("/assets/font/722178/497c3588-4b93-4f85-bcbc-52af2bdf03df.eot?#iefix");
  src: url("/assets/font/722178/497c3588-4b93-4f85-bcbc-52af2bdf03df.eot?#iefix") format("eot"), url("/assets/font/722178/46195450-8eef-4511-ac19-062ed9a6f1a0.woff2") format("woff2"), url("/assets/font/722178/78cb2356-22e7-4eae-bbb7-f947f2384d4b.woff") format("woff"), url("/assets/font/722178/ac1fbae2-c358-43bd-8789-791cd09a114d.ttf") format("truetype");
}

body {
  background: $white;
  font-weight: 300;
  scroll-behavior: smooth;
}

b,
strong {
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font-family;
  color: $primary-color;
  hyphens: auto;
}

h1,
h2 {
  font-size: 2.5rem;
}

h1 {
  @include breakpoint(medium down) {
    font-size: 30px;
  }

  @include breakpoint(small down) {
    font-size: 28px;
  }
}

h2 {
  @include breakpoint(medium down) {
    font-size: 28px;
  }

  @include breakpoint(small down) {
    font-size: 24px;
  }
}

.slick-slide {
  &:focus {
    outline: none;
  }
}

#main-wrapper {
  min-height: 300px;
  padding-top: 110px;

  @include breakpoint(medium down) {
    padding-top: 60px;
  }
}

.page-header {
  width: $global-width;
  margin: 0 auto;
  padding-top: 50px;

  @media screen and (max-width: $global-width) {
    width: 100%;
  }

  @include breakpoint(medium down) {
    padding-top: 20px;
  }

  .breadcrumb {
    ul {
      margin: 0;

      li {
        display: inline-block;
        color: $black;
        font-family: $primary-font-family;

        a,
        h1 {
          font: {
            size: 20px;
          }

          color: $black;

          &:hover {
            color: $primary-color;
          }

          @include breakpoint (medium down) {
            font-size: 18px;
          }
        }

        &:after {
          content: '/';
          display: inline-block;
          font-size: 20px;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;

          &:after {
            display: none;
          }
        }

        &:last-child {
          display: block;

          font: {
            size: 2.5rem;
          }

          color: $primary-color;
          line-height: 2.5rem;

          a,
          h1 {
            display: block;

            font: {
              size: 30px;
            }

            color: $primary-color;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.content-wrapper {
  .content-wrapper-box {
    width: $global-width;
    position: relative;
    margin: 0 auto;
    padding: 0 50px;

    @media screen and (max-width: $global-width) {
      width: 100%;
    }
  }

  &.fullwidth {
    .content-wrapper-box {
      width: 100%;
      padding: 0;
    }

    &-align-left {
      .content-wrapper-box {
        width: 97.6rem;
        position: relative;

        @media screen and (max-width: 1602px) {
          width: 100%;
          padding-right: 40px;
        }

        @include breakpoint(small down) {
          padding: 0 40px;
        }

        .justify-center {
          padding: 100px 0;

          @include breakpoint(medium down) {
            padding: 50px 0;

            &:first-child {
              margin-bottom: 50px;
            }
          }
        }
      }
    }
  }

  &.align-right,
  &.align-left {
    width: 95%;
    position: relative;

    @media screen and (max-width: 1450px) {
      width: 100%;
    }

    .content-wrapper-box {
      width: $global-width;
      position: relative;
      left: calc(51% - 37.5rem);
      padding: 0 50px;

      @media screen and (max-width: 1450px) {
        left: inherit;
        margin: 0 auto;
      }

      @media screen and (max-width: $global-width) {
        width: 100%;
      }

      @include breakpoint(small down) {
        padding: 0 40px;
      }
    }
  }

  &.align-left {
    left: 0;
  }

  &.align-right {
    left: 5%;

    .content-wrapper-box {
      left: calc(46% - 37.9rem);

      @media screen and (max-width: 1450px) {
        left: inherit;
      }
    }

    @media screen and (max-width: 1450px) {
      left: inherit;
    }
  }

  &.inner-space-vert {
    padding-top: 40px;
    padding-bottom: 40px;

    @include breakpoint(medium down) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @include breakpoint(small down) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &.outer-space-vert {
    margin-top: 40px;
    margin-bottom: 40px;

    @include breakpoint(medium down) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @include breakpoint(small down) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &.inner-space-hor {
    padding-left: 5%;
    padding-right: 5%;
  }

  /*&.COLOR {
    background: COLOR;
  }*/

  &.black {
    background: $black;
    position: relative;
  }

  &.light-grey {
    background: #F4F4F4;
    position: relative;
  }

}

.product-detail-start {
  .product-detail-slider-main {
    margin-bottom: 20px;
  }

  .product-detail-slider-nav {
    @include breakpoint(medium down) {
      margin-bottom: 40px;
    }

    .slick-slide {
      border: 1px solid $light-gray;
      margin-right: 20px;

      &.slick-current {
        border-color: $black;
      }
    }
  }
}

.parallax {
  height: 300px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin-bottom: 40px;

  @include breakpoint(small down) {
    background: inherit;
    background-size: cover;
    background-position: center;
  }
}

.parallax-1 {
  background-image: url("/assets/img/_X0A2930ret.jpg");
}

.headline-banner {
  text-align: center;
  position: relative;
  //display: grid;

  @include breakpoint(small down) {
    margin-bottom: 20px;
  }

  span {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: $black;
    font-family: "Impact", Helvetica, Arial, sans-serif;

  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    background: $white;
    position: relative;
    z-index: 10;
    //display: inline;
    hyphens: auto;
    display: block;
    max-width: 100%;
    width: max-content;

    font: {
      family: $primary-font-family;
      size: 34px;
    }

    line-height: 44px;
    //text-transform: uppercase;
    color: $primary-color;
    padding: 0 20px;
    margin: 0 auto;

    @include breakpoint(small down) {
      padding: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: $light-gray;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 9;
  }
}

.content-wrapper {
  &.light-grey {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      background: $ultra-light-gray;
    }
  }
}

.trenner {
  display: block;
  width: 100%;
  height: 2px;
  background: $light-gray;
}

.content-fullwidth-5050 {
  display: flex;
  flex-direction: row;

  @include breakpoint(small down) {
    display: block;

    border-bottom: 0;
  }

  .left,
  .right {
    width: 50%;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(medium down) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @include breakpoint(small down) {
      width: 100%;
    }
  }

  .left {
    border-right: 2px solid $light-gray;
    padding-left: calc(50% - 37.5rem + 20px);
    padding-right: 40px;

    @media screen and (max-width: $global-width) {
      padding-left: 20px;
    }

    @include breakpoint(small down) {
      border-right: 0 none;
      border-bottom: 2px solid $light-gray;
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .right {
    padding-right: calc(50% - 37.5rem + 20px);
    padding-left: 40px;

    @media screen and (max-width: $global-width) {
      padding-right: 20px;
    }

    @include breakpoint(small down) {
      padding-bottom: 0px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.product-info-list {
  ul {
    margin: 0;
    padding: 0 0 0 15px;

    li {
      position: relative;
      display: block;
      list-style: none;
      font-weight: 400;

      font: {
        size: 18px;
      }

      line-height: 24px;
      color: $black;
      margin-right: 5px;
      margin-bottom: 10px;

      &:before {
        content: '#';
        position: absolute;
        top: 2px;
        left: -15px;
        display: inline;

        font: {
          size: 16px;
          weight: 500;
        }

        color: $primary-color;
      }

      a {
        color: $black;

        font: {
          size: 18px;
        }

        line-height: 24px;
        text-decoration: underline;
      }
    }
  }

  .button {
    margin-top: 40px;
    padding: 20px;

    font: {
      family: $primary-font-family;
      size: 26px;
    }

    line-height: 26px;
    color: $white;
    background: $black;

    @include breakpoint(medium down) {
      width: 100%;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &.-lite {
    ul {
      li {
        // font-family: 'Roboto';
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
}

.product-info-colorvariants {
  .title {
    font: {
      size: 16px;
      weight: 600;
    }

    color: $black;
    margin-bottom: 20px;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;

    @include breakpoint(small down) {
      flex-direction: column;
    }

    li {
      width: calc(33% - 8px);
      margin-right: 12px;
      margin-bottom: 12px;
      list-style: none;

      &:nth-child(3n) {
        margin-right: 0;
      }

      @include breakpoint(small down) {
        width: 100%;
        margin: 0 0 20px;
      }

      ul {
        display: block;
        margin-bottom: 20px;

        li {
          display: inline;
          list-style: none;

          font: {
            size: 13px;
          }

          color: $black;
          margin-right: 5px;
          margin-bottom: 5px;
          line-height: 13px;

          &:before {
            content: '#';
            display: inline;

            font: {
              size: 13px;
              weight: 500;
            }

            color: $primary-color;
            margin-right: 4px;
            line-height: 13px;
          }
        }
      }
    }
  }
}

.product-info-facts {
  background: $white;

  ul {
    margin: 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $global-width) {
      flex-wrap: wrap;
    }

    li {
      width: 100px;
      display: flex;
      flex-direction: column;
      border-left: 2px solid $light-gray;
      padding: 5px 15px;

      &:last-child {
        border-right: 2px solid $light-gray;

        @media screen and (max-width: $global-width) {
          width: 33.5%;
        }

        @include breakpoint(medium down) {
          width: 50.25%;
        }

        @include breakpoint(small down) {
          width: 100%;
          border-bottom: 0 none;
          border-right: 0 none;
        }
      }

      @media screen and (max-width: $global-width) {
        width: 33.33%;
        padding: 15px;
        border-bottom: 2px solid $light-gray;
        flex-direction: row;

        &:nth-child(3n) {
          border-right: 2px solid $light-gray;
        }
      }

      @include breakpoint(medium down) {
        width: 50%;
        border-bottom: 2px solid $light-gray;

        &:nth-child(3n) {
          border-bottom: 2px solid $light-gray;
          border-right: 0 none;
        }

        &:nth-child(2n) {
          border-bottom: 2px solid $light-gray;
          border-right: 2px solid $light-gray;
        }
      }

      @include breakpoint(small down) {
        width: 100%;
        border: 0 none;

        &:nth-child(2n) {
          border: 0 none;
          border-bottom: 2px solid $light-gray;
        }

        border-bottom: 2px solid $light-gray;
      }

      .facts-label {
        width: 69px;
        height: 69px;
        margin-bottom: 20px;

        font: {
          size: 14px;
        }

        word-wrap: break-word;

        @media screen and (max-width: $global-width) {
          width: 85px;
          height: 85px;
          margin-right: 15px;
          margin-bottom: 0;
        }

        &.icon-label {
          &:after {
            width: 69px;
            height: 69px;
            content: '';
            display: block;
            background-repeat: no-repeat;
            background-size: cover;

            @media screen and (max-width: $global-width) {
              width: 85px;
              height: 85px;
            }
          }
        }

        &.icon-label-allergie {
          &:after {
            background-image: url("/assets/img/icon_allergy_free.svg");
          }
        }

        &.icon-label-helm {
          &:after {
            background-image: url("/assets/img/icon_with_helmet.svg");
          }
        }

        &.icon-label-gehoerschutz {
          &:after {
            background-image: url("/assets/img/icon_with_ear_protector.svg");
          }
        }

        &.icon-label-elektro {
          &:after {
            background-image: url("/assets/img/icon_electrical_work.svg");
          }
        }

        &.icon-label-nasenpad {
          &:after {
            background-image: url("/assets/img/icon_soft_nosepad.svg");
          }
        }

        &.icon-label-ergonomisch {
          &:after {
            background-image: url("/assets/img/icon_ergonomic.svg");
          }
        }

        &.icon-label-DIN-166 {
          &:after {
            background-image: url("/assets/img/icon_din_en_166.svg");
          }
        }

        &.icon-label-verglasbar {
          &:after {
            background-image: url("/assets/img/icon_dpt.svg");
          }
        }
      }

      .facts-value {
        word-wrap: break-word;

        font: {
          size: 12px;
        }

        @media screen and (max-width: $global-width) {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.similar-products {
  background: $ultra-light-gray;
  padding: 50px 0;
  margin-bottom: 40px;

  &.-w-100 {
    width: 100%;

    .slider-similar-products {
      width: 100%;
    }
  }

  &.-white-bg {
    background: $white;
  }

  .slider-similar-products {
    width: $global-width;
    margin: 0 auto;

    @media screen and (max-width: $global-width) {
      width: 100%;
    }
  }

  .slick-slide {
    background: $white;
    margin: 0 10px;
    transition: all 0.3s 0.7s;

    @include breakpoint(small down) {
      margin: 0 50px;
    }

    .similar-products-content {
      background: $black;
      padding: 15px 20px 5px;
    }

    .similar-products-title {
      color: $primary-color;

      font: {
        family: $primary-font-family;
        size: 28px;
      }

      line-height: 28px;
    }

    .similar-products-button {
      width: 100%;

      a {
        margin: 0;
        background: $black;
        width: 100%;
        padding: 0 20px 14px;

        font: {
          family: $primary-font-family;
          size: 22px;
        }

        line-height: 22px;
        text-align: left;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.multibox-big {
  width: 100%;
  background: $ultra-light-gray;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .media {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;

    @include breakpoint(small down) {
      padding: 25px;
    }

    .headline {
      display: block;
      margin: 0 0 20px;

      * {
        margin: 0;
        padding: 0;

        font: {
          family: $primary-font-family;
          size: 28px;
        }

        color: $primary-color;
        line-height: 28px;
      }
    }

    .button {
      display: inline-table;

      padding: 10px;

      font: {
        family: $primary-font-family;
        size: 20px;
      }

      line-height: 20px;
      color: $white;
      text-transform: uppercase;
      background: $black;

      &:hover {
        color: $primary-color;
      }
    }

    p {
      margin-bottom: 25px;

      font: {
        size: 16px;
        weight: 300;
      }

      line-height: 22px;
    }
  }
}

.start-slider {
  height: calc(100vh - 80px);

  @include breakpoint(medium down) {
    height: calc(100vh - 60px);
  }

  .slick-prev,
  .slick-next {
    bottom: 30px;
  }

  .slick-slide {
    position: relative;
    height: calc(100vh - 80px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include breakpoint(medium down) {
      height: calc(100vh - 60px);
    }
  }

  .slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 800;
  }

  .slider-content {
    height: 140%;
    position: relative;
    z-index: 900;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 220px 20px 0;
    font-family: $primary-font-family;

    @include breakpoint(small down) {
      height: 100%;
    }

    .headline {
      padding: 5px 18px;
      background: $primary-color;

      font: {
        size: 74px;
      }

      line-height: inherit;
      color: $white;
      text-align: center;
      text-transform: uppercase;

      @include breakpoint(medium down) {
        font: {
          size: 48px;
        }
      }

      // @include breakpoint(small down) {
      //   padding: 5px 18px;

    //   font: {
    //     size: 28px;
    //   }

    //   text-align: left;
    // }
    }

    .content {
      padding: 10px 15px;

      font: {
        size: 36px;
      }

      line-height: inherit;
      background: $white-trans-90;
      text-align: center;
      color: $black;

      @include breakpoint(medium down) {
        font: {
          size: 28px;
        }
      }

      @include breakpoint(small down) {
        padding: 5px 10px;

        font: {
          size: 18px;
        }
      }
    }
  }
}

.slider-count-wrapper {
  width: $global-width;
  margin: 0 auto;
  padding: 15px 25px 0;

  @media screen and (max-width: $global-width) {
    width: 100%;
  }

  .slider-count {
    font: {
      family: $primary-font-family;
      size: 24px;
    }

    line-height: 24px;
    color: $primary-color;
  }
}

.contact-content-wrapper {
  margin-bottom: 40px;

  .contact-box {
    width: 100%;
    background: $ultra-light-gray;
    padding: 30px;
  }

  .contact-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .button {
      display: inline-table;
      margin: 0;
      padding: 10px;

      font: {
        family: $primary-font-family;
        size: 20px;
      }

      line-height: 20px;
      color: $white;
      text-transform: uppercase;
      background: $black;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.contact-box {
  @include breakpoint(small down) {
    margin-bottom: 20px;
  }
}

.contact-box-single {
  .contact-content {
    display: flex;
    flex-direction: row;

    @include breakpoint(small down) {
      flex-direction: column;
    }
  }

  .headline-box {
    margin-bottom: 20px;

    .contact-title {
      display: block;
      margin: 0 0 10px;

      font: {
        family: $primary-font-family;
        size: 26px;
      }

      text-transform: uppercase;
      color: $primary-color;
      line-height: 30px;

      @include breakpoint(small down) {
        font-size: 22px;
        line-height: 24px;
      }
    }

    .contact-subtitle {
      font: {
        size: 18px;
      }

      color: $medium-gray;
      line-height: 20px;

      @include breakpoint(small down) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  .contact-image {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    background: $white;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      width: 70px;
      height: auto;
    }

    @include breakpoint(medium down) {
      margin-right: 20px;
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: auto;
      }
    }

    @include breakpoint(small down) {
      margin-right: 30px;
      margin-bottom: 30px;
      width: 70px;
      height: 70px;

      img {
        width: 70px;
        height: auto;
      }
    }
  }

  .contact-info {
    margin-bottom: 5px;
    position: relative;
    padding-left: 30px;

    font: {
      size: 18px;
    }

    line-height: 22px;
    color: $dark-gray !important;

    @include breakpoint(small down) {
      font: {
        size: 16px;
      }

      line-height: 20px;
    }

    a {
      margin: 0;
      padding: 0;
      color: $primary-color !important;
    }

    .label {
      background: 0 none;
      padding: 0;
      margin: 0;
      color: $medium-gray;
      font-size: 18px;

      @include breakpoint(small down) {
        font: {
          size: 16px;
        }

        line-height: 20px;
      }
    }

    &.contact-icon {
      &:before {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        color: $primary-color;

        font: {
          family: FontAwesome;
          size: 18px;
        }
      }

      &.phone {
        &:before {
          content: '\f095';
        }
      }

      &.fax {
        &:before {
          content: '\f1ac';
        }
      }

      &.mail {
        &:before {
          content: '\f003';
        }
      }

      &.time {
        &:before {
          content: '\f017';
        }
      }

      &.location {
        margin-bottom: 15px;

        a {
          font-size: 14px;
          font-weight: 600;
        }

        &:before {
          content: '\f124';
        }
      }

      &.web {
        &:before {
          content: '\f0c1';
        }
      }
    }
  }
}

.news-article {
  background: $ultra-light-gray;
  padding: 30px;

  time {
    display: inline-block;

    font: {
      family: $body-font-family-con;
      size: 16px;
      weight: 600;
    }

    line-height: 16px;
    color: $dark-gray;
    margin-bottom: 10px;
  }

  .read-more,
  .back {
    padding: 10px;
    background: $primary-color;

    font: {
      size: 18px;
      weight: 600;
    }

    color: $white;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
      background: #d94100;
      transition: all 0.3s;
    }
  }

  figure {
    margin-bottom: 30px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &.-new {
    padding: 15px 15px 30px;
    min-height: 100%;

    header {
      min-height: 60px;
    }

    .imgWrapper {
      max-height: 300px;
      overflow: hidden;
      align-items: center;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        min-height: 300px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 21px;
      font-weight: normal;
    }

    p {
      margin-top: .5rem;
    }
  }
}

.selectNews {}

.news-list {
  .news-article {

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 26px;
      line-height: 30px;
    }
  }
}

.news-detail {
  .news-article {

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 30px;
    }
  }
}

label {
  font: {
    family: $primary-font-family;
    size: 22px;
  }

  color: $primary-color;
  text-transform: uppercase;

  &[for*="checkbox"] {
    font: {
      family: $body-font-family-con;
      size: 18px;
      weight: 400;
    }

    text-transform: inherit;
    line-height: 18px;
    position: relative;
    top: 2px;
    color: $dark-gray;

    a {
      text-decoration: underline;
      color: $primary-color;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

input {
  margin-bottom: 40px;

  &[type=text],
  &[type=password],
  &[type=search],
  &[type=email],
  &[type=number] {
    width: 100%;
    height: 48px;
    background: $white;
    outline: none;
    box-shadow: none;
    border: 2px solid $light-gray;
    color: $primary-color;

    font: {
      family: $body-font-family-con;
      size: 18px;
    }

    text-transform: uppercase;
    line-height: inherit;
    padding: 0 10px 1px;

    &:focus {
      outline: none;
      border: 2px solid $light-gray;
      border-bottom-color: $primary-color;
      box-shadow: none;
    }
  }
}

textarea {
  resize: none;
  height: 200px;
  background: $white;
  outline: none;
  box-shadow: none;
  border: 2px solid $light-gray;
  color: $dark-gray;

  font: {
    family: $body-font-family-con;
    size: 18px;
  }

  line-height: inherit;
  padding: 10px;

  &:focus {
    outline: none;
    border: 2px solid $light-gray;
    border-bottom-color: $primary-color;
    box-shadow: none;
  }
}

button {
  margin: 0;
  padding: 0;
  border: 0 none;
  box-shadow: none;
  outline: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &[type=submit] {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 15px;

    font: {
      family: $primary-font-family;
      size: 22px;
    }

    line-height: 22px;
    color: $white;
    text-transform: uppercase;
    background: $black;

    @include breakpoint(medium down) {
      width: 100%;
    }

    &:hover {
      color: $primary-color;
    }

    &:focus {
      outline: none;
      border: 0 none;
      box-shadow: none;
    }
  }
}

.select-wrapper {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-right: 1px solid $primary-color;
    border-top: 1px solid $primary-color;
    transform: rotate(135deg);
    position: absolute;
    right: 13px;
    top: 17px;
    transition: all 0.3s linear;

    &:hover {
      cursor: pointer;
    }
  }

  select {
    height: 48px;
    background: $white;
    border: 2px solid $light-gray;
    outline: none;
    box-shadow: none;
    color: $dark-gray;

    font: {
      family: $body-font-family-con;
      size: 18px;
    }

    line-height: inherit;
    padding: 0 10px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      border: 2px solid $light-gray;
      border-bottom-color: $primary-color;
      box-shadow: none;
    }
  }
}

// Map
.contact-map-wrapper {
  background: $ultra-light-gray;
  padding: 70px 0;

  .map-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 639px) {
      display: none;
    }

    .map {
      position: relative;

      .mapImage {
        height: auto;
        width: 100%;
      }

      .map-marker-wrapper {

        .map-marker-dot {
          position: absolute;
          cursor: pointer;

          .map-marker {
            position: relative;

            .map-marker-info-wrapper {
              display: flex;
              justify-content: center;
            }

            .map-marker-info {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              width: max-content;
              bottom: 25px;
            }

            img {
              height: 20px;
              width: 20px;
              margin-top: 6px;
            }

            span {
              text-transform: uppercase;
              background: black;
              color: white;
              font-size: 12px;
              line-height: 12px;
              font-weight: 500;
              padding: 4px 4px 2px 4px;
              font-family: "Impact", Helvetica, Arial, sans-serif;
              letter-spacing: 1px;
              position: relative;
              width: 100%;
              text-align: center;

              &:last-of-type,
              &:only-of-type {
                &::after {
                  display: none;
                }
              }

              &::after {
                content: '';
                position: absolute;
                height: 1px;
                width: 90%;
                left: 50%;
                transform: translate(-50%);
                bottom: -1px;
                z-index: 1;
                background: white;
              }
            }
          }
        }
      }
    }
  }

  .accordion {
    background: transparent;
    margin-bottom: 0;
    max-height: 765px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 639px) {
      max-height: unset;
    }

    .accordion-item {
      background: white;
      border: 1px solid #E2E2E2;
      margin-bottom: 11px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.is-active {
        .accordion-title {
          align-items: flex-start;

          span {
            color: $primary-color;
            transition: all .35s;
          }

          .button {
            display: none;
          }

          .close {
            display: block;
          }
        }
      }

      .accordion-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        padding: 15px;
        font-family: "Impact", Helvetica, Arial, sans-serif;

        &::before {
          display: none;
        }

        &:hover,
        &:focus {
          background: white;
        }

        .title {
          margin-right: 30px;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 1px;
          font-weight: 500;
          color: black;

          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 22px;
          }

          @media screen and (max-width: 639px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        .button {
          font-size: 12px;
          line-height: 12px;
          color: black;
          font-weight: 500;
          letter-spacing: 1px;
          padding: 7px 10px 4px 10px;
          background: white;
          border: 2px solid black;
          margin: 0;
          text-transform: uppercase;
        }

        .close {
          display: none;
          position: relative;
          height: 18px;
          width: 18px;

          &::after,
          &::before {
            content: '';
            position: absolute;
            height: 20px;
            width: 3px;
            background: $primary-color;
            transform: rotate(45deg);
          }

          &::before {
            transform: rotate(315deg);
          }
        }
      }

      .accordion-content {
        border: 0;
        padding-top: 0;

        .contact-box {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          @media screen and (max-width: 950px) {
            flex-direction: column;
            align-items: flex-start;
          }

          a>img {
            max-width: 120px;
            max-height: 80px;
            height: 100%;
            width: 100%;
            object-fit: contain;
            margin-right: 55px;

            @media screen and (max-width: 950px) {
              max-width: 140px;
              margin: 5px 0 20px 0;
            }
          }

          .contact-info {
            display: flex;
            flex-direction: column;

            a {
              color: black;

              &:hover {
                color: $primary-color;
              }

              img {
                width: 16px;
                height: 16px;
                margin-right: 12px;
                margin: 0 10px 0 0;
              }

              &.button {
                color: black;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1px;
                font-size: 12px;
                line-height: 12px;
                text-align: left;
                background: transparent;
                position: relative;
                padding: 0;
                margin: 18px 0 0 0;

                &::after {
                  content: '';
                  position: absolute;
                  border-bottom: 2px solid black;
                  border-right: 2px solid black;
                  height: 7px;
                  width: 7px;
                  transform: rotate(315deg);
                  top: 1px;
                  right: 39px;
                  transition: all .35s;
                }

                &:hover {
                  color: $primary-color;

                  &::after {
                    right: 36px;
                    border-color: $primary-color;
                    transition: all .35s;
                  }
                }
              }
            }
          }
        }

        .location-wrapper {
          .location {
            display: flex;
            flex-direction: column;
            margin-top: 16px;

            &:first-of-type {
              margin-top: 34px;
            }

            .title {
              display: block;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 1px;
              color: $primary-color;
              font-family: "Impact", Helvetica, Arial, sans-serif;

              @media screen and (max-width: 639px) {
                font-size: 14px;
              }
            }

            .address {
              display: block;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: black;
            }
          }
        }
      }
    }
  }
}

/* .contact-map-wrapper {
  position: relative;
  display: block;

  @include breakpoint(small down) {
    display: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0.8);
    }

    70% {
      -webkit-box-shadow: 0 0 0 20px rgba(218, 50, 42, 0);
    }

    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0.8);
      box-shadow: 0 0 0 0 rgba(218, 50, 42, 0.8);
    }

    70% {
      -moz-box-shadow: 0 0 0 20px rgba(218, 50, 42, 0);
      box-shadow: 0 0 0 10px rgba(218, 50, 42, 0);
    }

    100% {
      -moz-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0);
      box-shadow: 0 0 0 0 rgba(218, 50, 42, 0);
    }
  }

  .map-marker-wrapper {
    .map-marker {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: rgba(218, 50, 42, 0.8);
      animation: none;
      transition: background .35s;

      &:hover,
      &.is-active {
        background: rgba(218, 50, 42, 1);
        cursor: pointer;
        box-shadow: 0 0 0 rgba(218, 50, 42, 0.8);
        animation: pulse 2s infinite;
        transition: background .35s;
      }

      &.secondary {
        background-color: $black;
      }

      &-01 {
        top: 27%;
        left: 30%;
      }

      &-02 {
        top: 47%;
        left: 50%;
      }

      &-03 {
        top: 37%;
        left: 70%;
      }
    }
  }
}

.accordion {
  border: 0 none !important;

  * {
    border: 0 none !important;
  }

  .accordion-title {
    font: {
      family: $primary-font-family;
      size: 20px;
    }

    transition: background-color .35s;
    padding-right: 50px;

    &:before {
      top: 28px;
    }

    &:focus {
      background: 0 none;
      color: $primary-color;
    }

    &:hover {
      background: $ultra-light-gray;
      color: $primary-color;
      transition: background-color .35s;
    }
  }

  .accordion-item {
    &.is-active {
      .accordion-title {
        background: $black;
        color: $primary-color;
        transition: background-color .35s;
      }
    }
  }

  .accordion-content {
    padding: 20px 0 0;

    .contact-box {
      margin-bottom: 10px;

      &:last-of-type {
        margin: 0;
      }
    }
  }
} */


//NEOS Misc

.neos #neos-inspector .neos-inspector-form .neos-inspector-field label {
  color: #fff !important;
}

.neos [class^=“icon-“],
.neos [class*=”icon-“] {
  color: white !important;
}

.neos #neos-context-bar #neos-aloha-topmenu .chosen-container .chosen-drop .chosen-results li {
  color: white;
}

.neos label {
  text-transform: none;
}

.neos #neos-inspector .neos-inspector-form input[type="text"],
.neos #neos-inspector .neos-inspector-form input[type="password"],
.neos #neos-inspector .neos-inspector-form input[type="datetime"],
.neos #neos-inspector .neos-inspector-form input[type="datetime-local"],
.neos #neos-inspector .neos-inspector-form input[type="date"],
.neos #neos-inspector .neos-inspector-form input[type="month"],
.neos #neos-inspector .neos-inspector-form input[type="time"],
.neos #neos-inspector .neos-inspector-form input[type="week"],
.neos #neos-inspector .neos-inspector-form input[type="number"],
.neos #neos-inspector .neos-inspector-form input[type="range"],
.neos #neos-inspector .neos-inspector-form input[type="date"],
.neos #neos-inspector .neos-inspector-form input[type="email"],
.neos #neos-inspector .neos-inspector-form input[type="url"],
.neos #neos-inspector .neos-inspector-form input[type="search"],
.neos #neos-inspector .neos-inspector-form input[type="tel"],
.neos #neos-inspector .neos-inspector-form input[type="color"],
.neos #neos-inspector .neos-inspector-form input[type="number"],
.neos #neos-inspector .neos-inspector-form textarea,
.neos #neos-inspector .neos-inspector-form .neos-uneditable-input {
  text-transform: none;
}

.bg-text-top {
  background-color: black;

  &.slick-slide {
    background-size: contain;
    background-position: center bottom;

  }

  .slider-content {

    .headline {
      background-color: transparent;
      position: absolute;
      top: 20px;
      font-size: 106px;
      line-height: 1;
      width: 700px;
      text-align: center;
      transition: all .35s;
      font-family: $primary-font-family;

      @media screen and (max-width: 700px) {
        font-size: 74.5px;

        span {
          font-size: 81px;
        }
      }

      span {
        font-size: 103.5px;
        color: red;
        transition: all .35s;

        @media screen and (max-width: 700px) {
          font-size: 73px;
        }
      }
    }
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #da322a;
  font-weight: 500;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}

.h1,
.h2 {
  font-size: 2.5rem;

  @media screen and (max-width: 639px) {
    font-size: 2.25rem;
  }
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5,
.h6 {
  font-size: 1.25rem;
}

.headline {
  height: auto;
  left: calc(50% - 22rem + 2px);

  &.-black {
    >* {
      color: black;
    }
  }

  &.-text-center {
    >* {
      text-align: center;
    }
  }
}

.cellArticle {
  margin-bottom: 30px !important;
}

.stickyButton-wrapper {
  position: fixed;
  top: 252px;
  right: 0;
  z-index: 999;

  a {
    .stickyButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      width: 75px;
      background: black;
      transition: all 0.3s;
      border-top: .1px solid rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      border-left: 1px solid rgba(255, 255, 255, 0.8);

      @media screen and (max-width: 700px) {
        height: 60px;
        width: 60px;
      }

       @include breakpoint(small down) {
        height: 40px;
        width: 40px;
      }

      &:hover {
        background: rgb(29, 29, 29);
        transition: all 0.3s;
      }

      img {
        height: 40px;
        width: 40px;

        @include breakpoint(small down) {
          height: 20px;
          width: 20px;

        }
      }

      &.-small {
        img {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}

.headerImage {
  width: 100%;
  height: 40vw;
  max-height: 750px;
  min-height: 300px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.anchorTags {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 15px 0;
  }

  a {
    font-weight: 700;
    font-size: 22px;

    @media screen and (max-width: 1024px) {
      text-align: center;
      margin: 5px 0px;
    }
  }
}

.content-wrapper {
  .counter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0 21px 0;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 30px 0;
    }

    .counterItem {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: 700px) {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      span {
        color: $primary-color;
        font-family: 'Impact';
        text-align: center;
        font-size: 22px;

        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }

        @media screen and (max-width: 700px) {
          font-size: 16px;
        }
      }

      .counterNumber {
        font-size: 60px;
        width: 160px;
        color: white;
        font-weight: 900;

        @media screen and (max-width: 1024px) {
          font-size: 50px;
        }

        @media screen and (max-width: 700px) {
          font-size: 28px;
        }
      }
    }
  }

  &.light-grey {
    .counter-wrapper {
      .counterItem {
        span {
          color: black;
        }

        .counterNumber {
          color: $primary-color;
        }
      }
    }
  }
}

.order-wrapper {
  @media screen and (max-width: 700px) {
    display: none;
  }

  &.order-slider-wrapper {
    display: none;

    @media screen and (max-width: 700px) {
      display: block;


      .order-slider {
        .order-item {
          .order-header {
            display: flex;
            justify-content: center;

            .number {
              width: 31px;
              height: 31px;
              font-size: 14px;
            }

            img {
              display: none;
            }
          }

          .order-text {
            text-align: center;
          }

          .order-image {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .slick-arrow {
          left: -30px;
          top: 50%;

          &::before {
            background-image: none;
            border-bottom: 2px solid black;
            border-right: 2px solid black;
            width: 19px;
            height: 19px;
            transform: rotate(135deg);
            border-radius: 2px;
            left: 0;
            position: absolute;
          }
        }

        .slick-next {
          left: unset;
          right: -30px;

          &::before {
            transform: rotate(-45deg);
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .order-item {
    position: relative;

    .order-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .number {
        color: black;
        border: 2px solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 23px;
        height: 23px;
        font-family: 'Impact';
        font-weight: 500;
        font-size: 12px;
      }

      img {
        height: 21px;
      }
    }

    .order-image {
      margin-bottom: 35px;
    }

    .order-text {
      span {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        display: block;

        @media screen and (max-width: 700px) {
          margin-bottom: 5px;
        }
      }

      p {
        font-size: 16px;
      }
    }
  }
}

.single-product-slider-wrapper {
  .single-product-slider {
    .single-product-item {

      .single-product-image {}

      .single-product-content {
        .single-product-title {}
      }

      .single-product-button {}
    }
  }
}

.additional-button-margin a.button {
  margin: 0 10px;
}

.single-product-slider-wrapper {
  padding: 50px 0;
  margin-bottom: 40px;

  @include breakpoint(small down) {
    margin-bottom: 0;
  }

  .single-product-slider {
    width: 100%;
    margin: 0 auto;
  }

  .slider-count-wrapper {
    width: 100%;
  }

  .slick-slide {
    background: $white;
    margin: 0 10px;
    transition: all 0.3s 0.7s;

    .single-product-image {
      height: 380px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint(small down) {
        height: 200px;
      }

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
      }
    }

    .single-product-content {
      background: $black;
      padding: 15px 20px 5px;
      display: inline-flex;
    }

    .single-product-title {
      color: $primary-color;

      font: {
        family: $primary-font-family;
        size: 28px;
      }

      line-height: 28px;
    }

    .single-product-button {
      width: 100%;

      a {
        margin: 0;
        background: $black;
        width: 100%;
        padding: 0 20px 14px;

        font: {
          family: $primary-font-family;
          size: 22px;
        }

        line-height: 22px;
        text-align: left;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.category-item {
  padding: 0 4px;
  display: block;

  .imgWrapper {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 1px solid $black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
      max-width: 55px;
      display: block;
    }
  }

  span {
    font-size: 27px;
    line-height: 1;
    color: $primary-color;
    font-family: Impact, Helvetica, Arial, sans-serif;
    display: block;
    margin-top: 40px;
    text-align: center;
    hyphens: auto;

    @media screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 27px;
    }
  }
}

.retailer-info {
  >.imgWrapper {
    margin-bottom: 16px;
  }

  >div {
    &:last-of-type {
      display: flex;
      justify-content: space-between;
      margin-top: 64px;
      border-top: 1px solid $black;
      padding-top: 24px;

      @media screen and (max-width: $global-width) {
        margin-top: 48px;
      }

      @media screen and (max-width: 639px) {
        flex-direction: column;
      }

      .imgWrapper {
        max-width: 180px;

        @media screen and (max-width: 639px) {
          margin-top: 32px;
        }
      }

      >div {
        display: flex;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }
      }
    }
  }

  .contact-person {
    display: flex;
    flex-direction: column;

    span {
      &:first-of-type {
        font-family: Impact, Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: 1px;
      }

      font-size: 15px;
      line-height: 22px;
    }

    a {
      color: $black;
      font-size: 15px;
      line-height: 22px;
    }

    +.contact-person {
      margin-left: 80px;

      @media screen and (max-width: 1024px) {
        margin-left: unset;
        margin-top: 32px;
      }
    }
  }
}

.headline-box {
  &.-big {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 72px;
      line-height: 80px;
      font-weight: 400;
      font-family: "Impact", Helvetica, Arial, sans-serif;
      color: $black;
      hyphens: auto;

      @media screen and (max-width: $global-width) {
        font-size: 62px;
        line-height: 70px;
      }

      @media screen and (max-width: 639px) {
        font-size: 34px;
        line-height: 44px;
      }


    }
  }
}

.icon-item {
  .imgWrapper {
    height: 60px;
    margin-bottom: 12px;

    img {
      margin: 0 auto;
      display: block;
      height: 100%;
    }
  }

  span {
    font: {
      size: 15px;
      weight: 400;
    }

    display: block;
    line-height: 1;
    font-family: "Impact",
    Helvetica,
    Arial,
    sans-serif;
    color: $black;
    text-align: center;
  }
}

.map-locations {
  >span {
    font-size: 28px;
    line-height: 30px;
    font-weight: 400;
    font-family: "Impact", Helvetica, Arial, sans-serif;
    margin-bottom: 32px;
    display: block;
  }

  >div {
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      display: block;

      &:first-of-type {
        color: $primary-color;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: "Impact", Helvetica, Arial, sans-serif;
      }

      font-size: 14px;
      line-height: 18px;
      color: $black;
      font-weight: 500;
      width: 100%;

      +span {
        margin-top: 4px;
      }
    }

    +div {
      margin-top: 20px;
    }
  }
}

.prod-verg-wrapper {
  .content-wrapper-box {
    >div {
      .headline-box {
        * {
          font-size: 53px;
          line-height: 1;
          margin-bottom: 24px;

          @media screen and (max-width: 768px) {
            font-size: 34px;
            line-height: 44px;
          }
        }
      }
    }
  }
}

.calculator-wrapper {
  position: relative;

  .content-wrapper-box {
    >div {
      .headline-box {
        * {
          font-size: 53px;
          line-height: 60px;
          margin-bottom: 24px;

          @media screen and (max-width: 768px) {
            font-size: 34px;
            line-height: 44px;
          }
        }
      }

      .button {
        margin-top: 24px;
      }
    }
  }
}

.calculator {
  background: #F4F4F4;
  padding: 16px;

  table {
    border-collapse: collapse;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    thead {
      @media screen and (max-width: 768px) {
        display: none;
      }

      tr {
        td {
          border-bottom: 1px solid rgba(226, 226, 226, 1);
          padding: 0 2rem 20px;
          text-align: left;
          line-height: 1;
          font-size: 12px;
          font-weight: 500;

          &:first-of-type {
            border-right: 1px solid rgba(226, 226, 226, 1);
            padding: unset;
          }

          &:last-of-type {
            font-weight: 600;
          }
        }
      }
    }

    tbody {
      width: 100%;

      tr {
        width: 100%;
        padding-bottom: 24px;

        @media screen and (max-width: 768px) {
          padding-bottom: 16px;
        }

        @media screen and (max-width: 768px) {
          display: block;
          width: 100%;
          border-bottom: 1px solid rgba(226, 226, 226, 1);

          &:last-of-type {
            border-bottom: 0 none;
          }
        }

        +tr {
          padding: 24px 0;

          @media screen and (max-width: 768px) {
            padding: 16px 0;
          }
        }

        td {
          padding: 24px 0;

          +td {
            padding: 24px 32px;
            width: calc(100% / 4);

            @media screen and (max-width: 768px) {
              width: 50%;
              padding: 0 24px 0 0;
            }
          }

          &:first-of-type {
            border-right: 1px solid rgba(226, 226, 226, 1);
            display: flex;
            flex-direction: column;
            position: relative;

            @media screen and (max-width: 768px) {
              display: flex;
              width: 100%;
              padding: 0 0 8px 0;
              border-right: 0 none;
            }

            &::after {
              content: '';
              height: 100%;
              width: 1px;
              background: $black;
              right: 0;
              top: 0;
              bottom: 0;
            }

            span {
              border: 0 none;
              padding-bottom: 0;
              color: $primary-color;
              font-size: 12px;
              line-height: 16px;
              font-weight: 500;
              margin: unset;
              text-align: left;

              +span {
                padding-top: 0;
                color: $black;
                font-weight: 300;
                font-size: 10px;
              }
            }
          }

          &:not(&:first-of-type) {
            span {
              box-sizing: border-box;
              font-size: 12px;
              line-height: 1;
              justify-content: center;
              // margin: 0 auto;

              &.mobile-col-title {
                margin-bottom: 8px;
              }
            }
          }

          &:last-of-type {
            span {
              font-weight: 500;
              border: 0 none;

              +span {
                font-weight: 600;
              }
            }
          }

          input {
            background: #F4F4F4;
            border: 1px solid $color-gray-900;
            padding: 8px;
            border-radius: 2px;
            box-sizing: border-box;
          }

          .input-group {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: start;

            &.-icon {
              input {
                border-right: 0 none;
                padding-right: 0;
                width: 50px;
                border-radius: 1px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &:focus {
                  border-right: 0 none;
                }
              }

              .input-group-label {
                background: transparent;
                padding: 8px 4px;
                border-radius: 1px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }

            input {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 45px;
              height: 30px;
              font-size: 12px;
              line-height: 1;
              color: $black;
              font-weight: 300;
              border-radius: 1px;

              &:focus {
                background: transparent;
                border: 1px solid $black;
              }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type=number] {
              -moz-appearance: textfield;
            }
          }

          .mobile-col-title {
            display: none;
            border: 0 none;
            text-align: left;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            margin: unset;
            width: 50px;


            @media screen and (max-width: 768px) {
              display: block;
              padding-left: 0;
              width: 50px;
            }
          }

          @media screen and (max-width: 768px) {
            padding-top: 0;
            width: max-content;
          }

          @media screen and (max-width: 600px) {
            padding-top: 0;
            width: calc(100% / 3);

            .mobile-col-title {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.prod-verg {
  display: flex;
  border-collapse: collapse;

  thead,
  tbody {
    tr {
      td {
        height: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  thead {
    tr {
      td {
        font-size: 21px;
        line-height: 1;
        font-weight: 500;

        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      &:first-of-type {
        td {
          height: 120px;
        }
      }
    }
  }

  tbody {
    overflow: scroll;
    display: flex;
    width: 100%;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    tr {
      min-width: 150px;
      width: 100%;
      padding: 0 8px;

      @media screen and (max-width: 768px) {
        min-width: 125px;
      }

      td {
        width: 100%;

        &:first-of-type {
          height: 120px;

          .imgWrapper {
            display: block;
            margin-bottom: 8px;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
          }
        }
      }

      display: flex;
      flex-direction: column;

      td {
        align-items: center;

        span {
          font-size: 17px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;

          @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.primary-bg {
  background: $primary-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a {
    color: $black;
  }
}

.info-banner {
  color: $black;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    left: -40px;
    bottom: -103px;
    background-image: url('/assets/img/forsec-icon.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .content-wrapper-box {
    >div {
      .cell {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
        z-index: 1;

        >span {
          margin-top: 8px;
        }

        span,
        a {
          font-size: 15px;
          line-height: 1;
          font-weight: 400;
          font-family: "Impact", Helvetica, Arial, sans-serif;
          color: $black;
        }
      }
    }
  }
}

.retailer-map {
  .content-wrapper-box {
    >div {
      display: flex;
      justify-content: center;

      .cell {
        &:last-of-type {
          display: flex;

          >div {
            margin: auto 0;
          }
        }
      }

      .map-locations {
        a {
          color: $black;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.table-caption {
  margin-top: 8px;
  text-align: end;
  height: max-content;

  span {
    font-size: 10px;
    line-height: 13px;
    display: block;
    color: $black;
  }
}

.cellArticle {
  >.similar-products {
    .similar-products-item {
      @include breakpoint(small down) {
        margin: 0 10px;
      }
    }
  }
}

.modal {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 64px;
  width: 100%;
  max-width: 805px;
  background-color: #F4F4F4;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1005;

  &.active {
    display: flex;
  }

  .close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 32px;
    top: 32px;
    border: 2px solid $primary-color;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 2px;
      background-color: $primary-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .headline-box {
    display: flex;
    justify-content: center;

    >* {
      font-size: 40px;
      line-height: 40px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
      font-family: "Impact", Helvetica, Arial, sans-serif;
    }

    +p {
      margin-top: 24px;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    padding: 0;
    margin: 0;

    +a {
      margin-top: 40px;
    }
  }

  a {
    width: max-content;
  }
}

.layer {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 1000;
  }
}

.-elementCenter {
  display: flex;
  justify-content: center;
}

.date-header {
  display: flex;
  justify-content: center;

  >div {
    display: flex;

    +div {
      margin-left: 24px;
    }

    span {
      font-weight: 300;
      font-size: 13px;
      line-height: 20px;
      color: #000000;

      &:last-of-type {
        font-weight: 400;
        margin-left: 4px;
        display: block;
      }
    }
  }
}

.img-caption {
  margin-top: 12px;

  >* {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
  }
}

// Abstandsklassen
.p-b-16 {
  padding-bottom: 16px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-b-64 {
  padding-bottom: 64px;
}

.p-t-64 {
  padding-top: 64px;
}

.contact-box-item {
  width: 372px;
  padding: 16px;
  background: white;
  border: 1px solid #E2E2E2;

  ul {
    margin: 24px 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  >span {
    font-family: 'Impact';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  a {
    color: black;

    &:hover {
      color: $primary-color;
    }

    img {
      width: 16px;
      height: 16px;
      margin-right: 12px;
      margin: 0 10px 0 0;
    }
  }

  button {
    padding: 4px 8px;
    background: #FFFFFF;
    border: 2px solid #000000;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: 'Impact';

    &:hover {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
}

.bold {
  font-family: 'Impact';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.catalog-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

  @media screen and (max-width: 1024px) {
    grid-gap: 16px;
  }

  .imgWrapper {
    display: block;
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  .button-border {
    margin-top: 8px;
  }
}


.button-border {
  padding: 4px 8px;
  background: #FFFFFF;
  border: 2px solid #000000;
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Impact';
  display: block;
  width: 100%;
  text-align: center;

  &:hover {
    border-color: $primary-color;
    color: $primary-color;
  }
}

.news-item {
  border: 1px solid #E2E2E2;
  display: block;

  .imgWrapper {
    width: 100%;
    display: block;
    border-bottom: 3px solid $primary-color;
    max-height: 260px;
    overflow: hidden;
    aspect-ratio: 372 / 320;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      transition: all .35s linear;
    }
  }

  .date-header {
    justify-content: flex-start;
    flex-wrap: wrap;

    div {
      display: flex;
      // flex-wrap: wrap;

      span {
        font-family: 'FrutigerNext';
        font-weight: 300;

        &:last-of-type {
          font-weight: 500;
        }
      }

      &:first-of-type {
        margin-right: 24px;
      }

      +div {
        margin-left: 0;
      }
    }

    // @include breakpoint(medium down) {
    //   flex-direction: column;

    //   div {
    //     +div {
    //       margin-left: 0;
    //     }
    //   }
    // }
  }

  .news-item-content {
    padding: 32px 16px 16px;
    background: white;
    box-sizing: border-box;

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      color: #000000;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
    }
  }

  &:hover {
    .imgWrapper {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.imgWrapper {
  &.-border-grey {
    img {
      border: 1px solid #E2E2E2;
    }
  }
}

.virtual-try-on {
  margin-bottom: 70px;

  @include breakpoint(small down) {
    margin-bottom: 30px;
  }

  .content-wrapper-box {
    @include breakpoint(small down) {
      padding: 0 16px;
    }
  }

  .virtual-try-on-overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.64);
    z-index: 999;
    padding: 10%;

    &.-active {
      display: flex;
    }

    .virtual-try-on-overlay-content {
      position: relative;
      background-color: white;
      max-width: 768px;
      width: 100%;
      padding: 80px;
      text-align: center;

      @include breakpoint(small down) {
        padding: 40px;
      }

      .headline-box {
        margin-bottom: 24px;

        > * {
          font-size: 40px;
          font-weight: 400;
          line-height: 40px;
          text-transform: uppercase;
          color: black;

          @include breakpoint(small down) {
            font-size: 28px;
            line-height: 28px;
          }
        }
      }

      p {
        margin-bottom: 40px;

        @include breakpoint(small down) {
          margin-bottom: 20px;
        }
      }

      .button {
        font-weight: 600;
        margin: 0;

        @include breakpoint(small down) {
          font-size: 16px;
        }
      }

      .close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        background-image: url("/assets/img/icon-close.svg");
        background-size: contain;
      }
    }
  }

  .virtual-try-on-glasses {
    position: relative;

    .virtual-try-on-slider {
      display: flex;

      @include breakpoint(small down) {
        flex-direction: column;
      }

      .slider-content-wrapper {
        position: relative;
        border: 1px solid black;
        aspect-ratio: 600 / 560;
        width: calc(100% - 96px);
        margin-right: 24px;
        max-height: 560px;

        @include breakpoint(small down) {
          width: 100%;
        }

        .virtual-try-on-switch {
          position: absolute;
          top: 16px;
          right: 50%;
          transform: translateX(50%);
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          width: max-content;
          color: white;
          padding: 16px 20px;
          border-radius: 40px;
          background-color: black;
          cursor: pointer;
          z-index: 10;

          @include breakpoint(small down) {
            font-size: 14px;
            line-height: 18px;
            padding: 12px 16px;
            right: 50%;
          }

          &.-light {
            background-color: #F4F4F4;
            color: black;
          }
        }

        .virtual-try-on-camera-button {
          position: absolute;
          bottom: 24px;
          left: 50%;
          display: none;
          justify-content: center;
          align-items: center;
          transform: translateX(-50%);
          z-index: 1;
          height: 54px;
          width: 54px;
          border-radius: 50%;
          border: 4px solid white;
          background-color: transparent;
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.80));
          cursor: pointer;

          @include breakpoint(small down) {
            left: 50%;
            bottom: 16px;
          }

          &.-active {
            display: flex;
          }

          &::after {
            content: '';
            width: 38px;
            height: 38px;
            position: absolute;
            border-radius: 50%;
            background-color: white;
          }
        }

        .virtual-try-on-function-list {
          position: absolute;
          bottom: -49px;
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;

          .virtual-try-on-item {
            display: none;
            align-items: center;
            margin-right: 48px;
            cursor: pointer;

            @include breakpoint(small down) {
              margin-right: 24px;
            }

            &.-active {
              display: flex;
            }

            &:hover {
              span {
                text-decoration: underline;
              }
            }

            img {
              width: 36px;
              height: 36px;
              background-color: black;
              padding: 8px;
              border-radius: 50%;
              margin-right: 8px;

              @include breakpoint(small down) {
                width: 32px;
                height: 32px;
              }
            }

            span {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;

              @include breakpoint(small down) {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }

        .slider-content {
          height: 100%;

          .slick-list {
            height: 100%;

            .slick-track {
              height: 100%;

              .slider-item {
                .imgWrapper {
                  height: 100%;

                  img {
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }

      .slider-thumb {
        width: 96px;
        max-height: 560px;

        @include breakpoint(small down) {
          width: 100%;
          margin-top: 76px;
        }

        .slick-list {
          margin: 64px 0;

          @include breakpoint(small down) {
            margin: 0;
            overflow: visible;
          }
        }

        .slider-thumb-item {
          box-shadow: inset 0px 0px 0px 1px #000000;
          border-radius: 4px;
          margin: 0 0 16px 0;
          height: 96px;
          width: 96px;

          @include breakpoint(small down) {
            height: unset;
            width: unset;
            margin: 0 8px 0 0;
            aspect-ratio: 1 / 1;
          }

          &.slick-current {
            box-shadow: inset 0px 0px 0px 2px $primary-color;
          }

          &.slick-active {
            &:last-of-type {
              margin-bottom: 0;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          .imgWrapper {
            aspect-ratio: 96 / 96;

            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              padding: 2px;
            }
          }
        }
      }

      .slick-prev,
      .slick-next {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: black;

        &::before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          opacity: 1;
          top: 18px;
          background-image: unset;
          border-top: 3px solid white;
          border-right: 3px solid white;
          transform: rotate(-45deg);
        }
      }

      .slick-next {
        top: unset;
        bottom: 0;

        &::before {
          top: 13px;
          transform: rotate(135deg);
        }
      }
    }
  }

  .virtual-try-on-description {
    @include breakpoint(large down) {
      margin-top: 60px;
    }
    @include breakpoint(small down) {
      margin-top: 0px;
    }

    .virtual-try-on-description-item {
      display: none;

      &.-active {
        display: block;
      }

      .category {
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
      }

      .headline-box {
        margin-bottom: 24px;

        * {
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px;
          color: black;
        }
      }

      ul {
        margin: 0 0 40px 0;
        padding: 0 0 0 21px;
        list-style: none;

        @include breakpoint(small down) {
          padding: 0 0 0 18px;
          margin: 0 0 24px 0;
        }

        li {
          position: relative;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 16px;

          @include breakpoint(small down) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 8px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          &::after {
            content: '#';
            position: absolute;
            top: -1px;
            left: -22px;
            display: inline;
            font-size: 23px;
            font-weight: 500;
            color: $primary-color;

            @include breakpoint(small down) {
              top: 1px;
              font-size: 18px;
              left: -17px;
            }
          }
        }
      }

      a {
        position: relative;
        color: black;
        padding: 0 18px 0 0;
        background-color: unset;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        text-align: left;

        &:hover {
          &::before,
          &::after {
            right: -3px;
          }
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 8px;
          right: 0;
          width: 9px;
          height: 2px;
          background-color: black;
          transform: rotate(45deg);
          transition: all .35s ease-in-out;
        }

        &::after {
          top: 13px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}


// iFrame startpage
.contact-box-start-page {
  display: flex;
  border: 1px solid rgba(226, 226, 226, 1);
  margin: 0;
  min-height: 100vh;
  width: 100vw;

  @include breakpoint(medium down) {
    flex-direction: column-reverse;
  }

  .contact-box-start-page__content {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 40px 56px;

    @include breakpoint(medium down) {
      width: 100%;
      padding: 32px;
      flex-grow: 1;
    }

    @include breakpoint(small down) {
      padding: 16px 24px;
    }

    .content-area {
      background: white;

      .headline {
        margin-bottom: 12px;

        @include breakpoint(small down) {
          margin-bottom: 4px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 48px;
          font-weight: 400;
          line-height: 56px;
          color: $primary-color;

          @include breakpoint(medium down) {
            font-size: 40px;
            line-height: 48px;
          }

          @include breakpoint(small down) {
            font-size: 36px;
            line-height: 44px;
          }
        }
      }

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: $black;
        margin: 0;

        @include breakpoint(medium down) {
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
        }
      }

      .button {
        position: relative;
        margin: 24px 0 0 0;
        padding-right: 60px;
        font-weight: 600;

        @include breakpoint(medium down) {
          margin: 16px 0 0 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            width: 18px;
            height: 16px;
            background-image: url('/assets/img/filled-arrow.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            transform: translateY(-50%);
        }
      }
    }

    .contact-area {
      margin-top: 40px;

      @include breakpoint(medium down) {
        margin-top: 32px;
      }

      @include breakpoint(small down) {
        margin-top: 16px;
      }

      .headline {
        margin-bottom: 4px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 32px;
          font-weight: 400;
          line-height: 38px;
          color: $black;
        }
      }

      p {
        font-size: 18px;
        line-height: 28px;
        color: $black;
        margin-bottom: 16px;

        @include breakpoint(medium down) {
          margin-top: 8px;
        }

        @include breakpoint(small down) {
          margin-top: 16px;
        }
      }

      .contact-details {
        a,
        span {
          display: block;
          font-size: 18px;
          line-height: 28px;
          color: $black;
          text-decoration: none;
          width: max-content;
        }

        a {
          &:hover {
            text-decoration: underline
          }
        }
      }
    }
  }

  .contact-box-start-page__image {
    position: relative;
    width: 50%;

    @include breakpoint(medium down) {
      width: 100%;
      max-height: 400px;
    }

    @include breakpoint(small down) {
      max-height: 220px;
    }

    .overlay-logo {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 50%;
      height: auto;
      max-width: 216px;
      filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.32));

      @include breakpoint(medium down) {
        max-width: 200px;
      }

      @include breakpoint(small down) {
        max-width: 120px;
        top: 16px;
        right: 16px;
      }
    }

    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;

      @include breakpoint(medium down) {
        max-height: 400px;
      }

      @include breakpoint(small down) {
        max-height: 220px;
      }
    }
  }

  &.-black {
    background: black;
    border: 1px solid black;

    .contact-box-start-page__content {
      .content-area {
        background: black;

        p {
          color: white;
        }
      }

      .contact-area {
        .headline {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: white;
          }
        }

        p {
          color: white;
        }

        .contact-details {
          a,
          span {
            color: white;
          }
        }
      }
    }
  }
}

// iFrame subpage
.contact-box-sub-page {
  position: relative;
  display: flex;
  border: 1px solid rgba(226, 226, 226, 1);
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  padding: 40px;

  @include breakpoint(medium down) {
    position: static;
    flex-direction: column;
  }

  @include breakpoint(small down) {
    padding: 16px;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 41px;
    width: max-content;
    margin-bottom: 32px;

    @include breakpoint(medium down) {
      position: static;
      order: 1;
    }
  }

  .contact-box-sub-page__content {
    width: calc(40% - 20px);
    margin-right: 20px;
    margin-top: 64px;

    @include breakpoint(medium down) {
      width: 100%;
      margin: 0;
      order: 3;
    }

    .content-area {
      background: white;

      .headline {
        margin-bottom: 12px;

        @include breakpoint(small down) {
          margin-bottom: 4px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 48px;
          font-weight: 400;
          line-height: 56px;
          color: $primary-color;

          @include breakpoint(medium down) {
            font-size: 40px;
            line-height: 48px;
          }

          @include breakpoint(small down) {
            font-size: 36px;
            line-height: 44px;
          }
        }
      }

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        color: $black;
        margin: 0;

        @include breakpoint(medium down) {
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
        }
      }

      .button {
        position: relative;
        margin: 40px 0 0 0;
        font-weight: 600;

        @include breakpoint(medium down) {
          margin: 32px 0 0 0;
        }
      }
    }
  }

  .contact-box-sub-page__image {
    position: relative;
    width: calc(60% - 20px);
    margin-left: 20px;

    @include breakpoint(medium down) {
      width: 100%;
      margin: 0 0 40px 0;
      order: 2;
    }

    .background-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  &.-black {
    background: black;
    border: 1px solid black;

    .contact-box-sub-page__content {
      .content-area {
        background: black;

        p {
          color: white;
        }
      }
    }
  }
}

.search-container {
  display: flex;
  margin-bottom: 24px;

  @include breakpoint(small down) {
    flex-direction: column;
  }

  .search-bar {
    color: $color-gray-900;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-transform: none;
    margin: 0 24px 0 0;
    padding: 15px 14px;
    height: 50px;
    border: 2px solid $color-gray-600;

    @include breakpoint(small down) {
      margin: 0 0 16px 0;
    }
  }

  .search-button {
    font-size: 22px;
    font-weight: 600;
    line-height: 16px;
    background-color: $primary-color;
    color: $white;
    padding: 17px 24px;
  }
}

.map {
  &.map-overlay {
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url("/assets/img/googlemaps-image.jpg");
    }
  }

  iframe {
    width: 100%;
    height: 560px;
  }

  .map-info-card {
    position: relative;
    display: none;
    flex-direction: column;
    padding: 50px 16px 24px 16px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .3);
    min-width: 263px;

    &.show {
      display: flex;
    }

    &::after {
      position: absolute;
      content: '';
      top: 100%;
      left: 50%;
      transform: rotate(0deg) translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: $white transparent transparent transparent;
      z-index: 1;
    }

    .close-button {
      position: absolute;
      top: 12px;
      right: 12px;
      background-image: url("/assets/img/icon-x.svg");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin: 0;
    }

    .title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: $primary-color;
      margin-bottom: 12px;
    }

    span {
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 10px;
      color: $color-gray-900;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ul {
      margin: 30px 0 0 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 16px;
        color: $color-gray-900;
        padding-left: 28px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &::after {
          position: absolute;
          content: '';
          top: -2px;
          left: 0;
          height: 16px;
          width: 16px;
          background-repeat: no-repeat;
          background-size: 16px 16px;
        }

        &.icon-house {
          &::after {
            background-image: url("/assets/img/icon-house.svg");
          }
        }

        &.icon-mail {
          &::after {
            background-image: url("/assets/img/icon-envelope.svg");
          }
        }

        &.icon-phone {
          &::after {
            background-image: url("/assets/img/icon-phone.svg");
          }
        }

        &.icon-globe {
          &::after {
            background-image: url("/assets/img/icon-globe.svg");
          }

          a {
            font-weight: 400;
            color: $primary-color;
          }
        }

        a {
          font-size: inherit;
          line-height: inherit;
          font-weight: inherit;
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
