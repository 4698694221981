@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif; /* 1 */
  line-height: 1.15; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=43.75em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: rgb(255, 255, 255);
  font-family: "FrutigerNext", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(64, 64, 64);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}
[data-whatinput=mouse] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.grid-container {
  padding-right: 0;
  padding-left: 0;
  max-width: 75rem;
  margin: 0 auto;
}
@media print, screen and (min-width: 43.75em) {
  .grid-container {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
}
.grid-container.fluid {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}
@media print, screen and (min-width: 43.75em) {
  .grid-container.fluid {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
}
.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}
.cell.auto {
  flex: 1 1 0px;
}
.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}
.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 43.75em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}
.grid-x > .small-1 {
  width: 8.3333333333%;
}

.grid-x > .small-2 {
  width: 16.6666666667%;
}

.grid-x > .small-3 {
  width: 25%;
}

.grid-x > .small-4 {
  width: 33.3333333333%;
}

.grid-x > .small-5 {
  width: 41.6666666667%;
}

.grid-x > .small-6 {
  width: 50%;
}

.grid-x > .small-7 {
  width: 58.3333333333%;
}

.grid-x > .small-8 {
  width: 66.6666666667%;
}

.grid-x > .small-9 {
  width: 75%;
}

.grid-x > .small-10 {
  width: 83.3333333333%;
}

.grid-x > .small-11 {
  width: 91.6666666667%;
}

.grid-x > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 43.75em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .medium-1 {
    width: 8.3333333333%;
  }
  .grid-x > .medium-2 {
    width: 16.6666666667%;
  }
  .grid-x > .medium-3 {
    width: 25%;
  }
  .grid-x > .medium-4 {
    width: 33.3333333333%;
  }
  .grid-x > .medium-5 {
    width: 41.6666666667%;
  }
  .grid-x > .medium-6 {
    width: 50%;
  }
  .grid-x > .medium-7 {
    width: 58.3333333333%;
  }
  .grid-x > .medium-8 {
    width: 66.6666666667%;
  }
  .grid-x > .medium-9 {
    width: 75%;
  }
  .grid-x > .medium-10 {
    width: 83.3333333333%;
  }
  .grid-x > .medium-11 {
    width: 91.6666666667%;
  }
  .grid-x > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .large-1 {
    width: 8.3333333333%;
  }
  .grid-x > .large-2 {
    width: 16.6666666667%;
  }
  .grid-x > .large-3 {
    width: 25%;
  }
  .grid-x > .large-4 {
    width: 33.3333333333%;
  }
  .grid-x > .large-5 {
    width: 41.6666666667%;
  }
  .grid-x > .large-6 {
    width: 50%;
  }
  .grid-x > .large-7 {
    width: 58.3333333333%;
  }
  .grid-x > .large-8 {
    width: 66.6666666667%;
  }
  .grid-x > .large-9 {
    width: 75%;
  }
  .grid-x > .large-10 {
    width: 83.3333333333%;
  }
  .grid-x > .large-11 {
    width: 91.6666666667%;
  }
  .grid-x > .large-12 {
    width: 100%;
  }
}
.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: - 0;
  margin-right: - 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-x {
    margin-left: - 1.5625rem;
    margin-right: - 1.5625rem;
  }
}
.grid-margin-x > .cell {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-x > .cell {
    width: calc(100% - 3.125rem);
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }
}
.grid-margin-x > .auto {
  width: auto;
}
.grid-margin-x > .shrink {
  width: auto;
}
.grid-margin-x > .small-1 {
  width: 8.3333333333%;
}
.grid-margin-x > .small-2 {
  width: 16.6666666667%;
}
.grid-margin-x > .small-3 {
  width: 25%;
}
.grid-margin-x > .small-4 {
  width: 33.3333333333%;
}
.grid-margin-x > .small-5 {
  width: 41.6666666667%;
}
.grid-margin-x > .small-6 {
  width: 50%;
}
.grid-margin-x > .small-7 {
  width: 58.3333333333%;
}
.grid-margin-x > .small-8 {
  width: 66.6666666667%;
}
.grid-margin-x > .small-9 {
  width: 75%;
}
.grid-margin-x > .small-10 {
  width: 83.3333333333%;
}
.grid-margin-x > .small-11 {
  width: 91.6666666667%;
}
.grid-margin-x > .small-12 {
  width: 100%;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-x > .small-2 {
    width: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-x > .small-3 {
    width: calc(25% - 3.125rem);
  }
  .grid-margin-x > .small-4 {
    width: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-x > .small-5 {
    width: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-x > .small-6 {
    width: calc(50% - 3.125rem);
  }
  .grid-margin-x > .small-7 {
    width: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-x > .small-8 {
    width: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-x > .small-9 {
    width: calc(75% - 3.125rem);
  }
  .grid-margin-x > .small-10 {
    width: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-x > .small-11 {
    width: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-x > .small-12 {
    width: calc(100% - 3.125rem);
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-x > .medium-2 {
    width: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-x > .medium-3 {
    width: calc(25% - 3.125rem);
  }
  .grid-margin-x > .medium-4 {
    width: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-x > .medium-5 {
    width: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-x > .medium-6 {
    width: calc(50% - 3.125rem);
  }
  .grid-margin-x > .medium-7 {
    width: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-x > .medium-8 {
    width: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-x > .medium-9 {
    width: calc(75% - 3.125rem);
  }
  .grid-margin-x > .medium-10 {
    width: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-x > .medium-11 {
    width: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-x > .medium-12 {
    width: calc(100% - 3.125rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-x > .large-2 {
    width: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-x > .large-3 {
    width: calc(25% - 3.125rem);
  }
  .grid-margin-x > .large-4 {
    width: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-x > .large-5 {
    width: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-x > .large-6 {
    width: calc(50% - 3.125rem);
  }
  .grid-margin-x > .large-7 {
    width: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-x > .large-8 {
    width: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-x > .large-9 {
    width: calc(75% - 3.125rem);
  }
  .grid-margin-x > .large-10 {
    width: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-x > .large-11 {
    width: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-x > .large-12 {
    width: calc(100% - 3.125rem);
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: - 0;
  margin-left: - 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-padding-x .grid-padding-x {
    margin-right: - 1.5625rem;
    margin-left: - 1.5625rem;
  }
}
.grid-container:not(.full) > .grid-padding-x {
  margin-right: - 0;
  margin-left: - 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: - 1.5625rem;
    margin-left: - 1.5625rem;
  }
}
.grid-padding-x > .cell {
  padding-right: 0;
  padding-left: 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-padding-x > .cell {
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.3333333333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.6666666667%;
}

.small-up-7 > .cell {
  width: 14.2857142857%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 43.75em) {
  .medium-up-1 > .cell {
    width: 100%;
  }
  .medium-up-2 > .cell {
    width: 50%;
  }
  .medium-up-3 > .cell {
    width: 33.3333333333%;
  }
  .medium-up-4 > .cell {
    width: 25%;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-6 > .cell {
    width: 16.6666666667%;
  }
  .medium-up-7 > .cell {
    width: 14.2857142857%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }
  .large-up-2 > .cell {
    width: 50%;
  }
  .large-up-3 > .cell {
    width: 33.3333333333%;
  }
  .large-up-4 > .cell {
    width: 25%;
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-6 > .cell {
    width: 16.6666666667%;
  }
  .large-up-7 > .cell {
    width: 14.2857142857%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
}
.grid-margin-x.small-up-1 > .cell {
  width: 100%;
}

.grid-margin-x.small-up-2 > .cell {
  width: 50%;
}

.grid-margin-x.small-up-3 > .cell {
  width: 33.3333333333%;
}

.grid-margin-x.small-up-4 > .cell {
  width: 25%;
}

.grid-margin-x.small-up-5 > .cell {
  width: 20%;
}

.grid-margin-x.small-up-6 > .cell {
  width: 16.6666666667%;
}

.grid-margin-x.small-up-7 > .cell {
  width: 14.2857142857%;
}

.grid-margin-x.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 43.75em) {
  .grid-margin-x.small-up-1 > .cell {
    width: 100%;
  }
  .grid-margin-x.small-up-2 > .cell {
    width: 50%;
  }
  .grid-margin-x.small-up-3 > .cell {
    width: 33.3333333333%;
  }
  .grid-margin-x.small-up-4 > .cell {
    width: 25%;
  }
  .grid-margin-x.small-up-5 > .cell {
    width: 20%;
  }
  .grid-margin-x.small-up-6 > .cell {
    width: 16.6666666667%;
  }
  .grid-margin-x.small-up-7 > .cell {
    width: 14.2857142857%;
  }
  .grid-margin-x.small-up-8 > .cell {
    width: 12.5%;
  }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 3.125rem);
  }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 3.125rem);
  }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 3.125rem);
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 3.125rem);
  }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857142857% - 3.125rem);
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 3.125rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 3.125rem);
  }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 3.125rem);
  }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 3.125rem);
  }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 3.125rem);
  }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.2857142857% - 3.125rem);
  }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 3.125rem);
  }
}
.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}
.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}
.small-margin-collapse > .small-1 {
  width: 8.3333333333%;
}
.small-margin-collapse > .small-2 {
  width: 16.6666666667%;
}
.small-margin-collapse > .small-3 {
  width: 25%;
}
.small-margin-collapse > .small-4 {
  width: 33.3333333333%;
}
.small-margin-collapse > .small-5 {
  width: 41.6666666667%;
}
.small-margin-collapse > .small-6 {
  width: 50%;
}
.small-margin-collapse > .small-7 {
  width: 58.3333333333%;
}
.small-margin-collapse > .small-8 {
  width: 66.6666666667%;
}
.small-margin-collapse > .small-9 {
  width: 75%;
}
.small-margin-collapse > .small-10 {
  width: 83.3333333333%;
}
.small-margin-collapse > .small-11 {
  width: 91.6666666667%;
}
.small-margin-collapse > .small-12 {
  width: 100%;
}
@media print, screen and (min-width: 43.75em) {
  .small-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}
.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 43.75em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 43.75em) {
  .medium-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 43.75em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 43.75em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0);
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.3333333333% + 0);
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.6666666667% + 0);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0);
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.3333333333% + 0);
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.6666666667% + 0);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0);
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.3333333333% + 0);
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.6666666667% + 0);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0);
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.3333333333% + 0);
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.6666666667% + 0);
}

@media print, screen and (min-width: 43.75em) {
  .medium-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 1.5625rem);
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.3333333333% + 1.5625rem);
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.6666666667% + 1.5625rem);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 1.5625rem);
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.3333333333% + 1.5625rem);
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.6666666667% + 1.5625rem);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 1.5625rem);
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.3333333333% + 1.5625rem);
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.6666666667% + 1.5625rem);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 1.5625rem);
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.3333333333% + 1.5625rem);
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.6666666667% + 1.5625rem);
  }
}
@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 1.5625rem);
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.3333333333% + 1.5625rem);
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.6666666667% + 1.5625rem);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 1.5625rem);
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.3333333333% + 1.5625rem);
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.6666666667% + 1.5625rem);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 1.5625rem);
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.3333333333% + 1.5625rem);
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.6666666667% + 1.5625rem);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 1.5625rem);
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.3333333333% + 1.5625rem);
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.6666666667% + 1.5625rem);
  }
}
.grid-y {
  display: flex;
  flex-flow: column nowrap;
}
.grid-y > .cell {
  width: auto;
}
.grid-y > .auto {
  height: auto;
}
.grid-y > .shrink {
  height: auto;
}
.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}
@media print, screen and (min-width: 43.75em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}
.grid-y > .small-1 {
  height: 8.3333333333%;
}
.grid-y > .small-2 {
  height: 16.6666666667%;
}
.grid-y > .small-3 {
  height: 25%;
}
.grid-y > .small-4 {
  height: 33.3333333333%;
}
.grid-y > .small-5 {
  height: 41.6666666667%;
}
.grid-y > .small-6 {
  height: 50%;
}
.grid-y > .small-7 {
  height: 58.3333333333%;
}
.grid-y > .small-8 {
  height: 66.6666666667%;
}
.grid-y > .small-9 {
  height: 75%;
}
.grid-y > .small-10 {
  height: 83.3333333333%;
}
.grid-y > .small-11 {
  height: 91.6666666667%;
}
.grid-y > .small-12 {
  height: 100%;
}
@media print, screen and (min-width: 43.75em) {
  .grid-y > .medium-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.3333333333%;
  }
  .grid-y > .medium-2 {
    height: 16.6666666667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.3333333333%;
  }
  .grid-y > .medium-5 {
    height: 41.6666666667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.3333333333%;
  }
  .grid-y > .medium-8 {
    height: 66.6666666667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.3333333333%;
  }
  .grid-y > .medium-11 {
    height: 91.6666666667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.3333333333%;
  }
  .grid-y > .large-2 {
    height: 16.6666666667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.3333333333%;
  }
  .grid-y > .large-5 {
    height: 41.6666666667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.3333333333%;
  }
  .grid-y > .large-8 {
    height: 66.6666666667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.3333333333%;
  }
  .grid-y > .large-11 {
    height: 91.6666666667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: - 0;
  margin-bottom: - 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-padding-y .grid-padding-y {
    margin-top: - 1.5625rem;
    margin-bottom: - 1.5625rem;
  }
}
.grid-padding-y > .cell {
  padding-top: 0;
  padding-bottom: 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-padding-y > .cell {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
}

.grid-margin-y {
  margin-top: - 0;
  margin-bottom: - 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-y {
    margin-top: - 1.5625rem;
    margin-bottom: - 1.5625rem;
  }
}
.grid-margin-y > .cell {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-y > .cell {
    height: calc(100% - 3.125rem);
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
}
.grid-margin-y > .auto {
  height: auto;
}
.grid-margin-y > .shrink {
  height: auto;
}
.grid-margin-y > .small-1 {
  height: 8.3333333333%;
}
.grid-margin-y > .small-2 {
  height: 16.6666666667%;
}
.grid-margin-y > .small-3 {
  height: 25%;
}
.grid-margin-y > .small-4 {
  height: 33.3333333333%;
}
.grid-margin-y > .small-5 {
  height: 41.6666666667%;
}
.grid-margin-y > .small-6 {
  height: 50%;
}
.grid-margin-y > .small-7 {
  height: 58.3333333333%;
}
.grid-margin-y > .small-8 {
  height: 66.6666666667%;
}
.grid-margin-y > .small-9 {
  height: 75%;
}
.grid-margin-y > .small-10 {
  height: 83.3333333333%;
}
.grid-margin-y > .small-11 {
  height: 91.6666666667%;
}
.grid-margin-y > .small-12 {
  height: 100%;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 3.125rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 3.125rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 3.125rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 3.125rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 3.125rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 3.125rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 3.125rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 3.125rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 3.125rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 3.125rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 3.125rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 3.125rem);
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 43.75em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .medium-grid-frame {
    width: 100%;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
}
@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .large-grid-frame {
    width: 100%;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
}
.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
}
@media print, screen and (min-width: 43.75em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}
@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}
@media print, screen and (min-width: 43.75em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: - 0;
  margin-bottom: - 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-y {
    margin-top: - 1.5625rem;
    margin-bottom: - 1.5625rem;
  }
}
.grid-margin-y > .cell {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-y > .cell {
    height: calc(100% - 3.125rem);
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }
}
.grid-margin-y > .auto {
  height: auto;
}
.grid-margin-y > .shrink {
  height: auto;
}
.grid-margin-y > .small-1 {
  height: 8.3333333333%;
}
.grid-margin-y > .small-2 {
  height: 16.6666666667%;
}
.grid-margin-y > .small-3 {
  height: 25%;
}
.grid-margin-y > .small-4 {
  height: 33.3333333333%;
}
.grid-margin-y > .small-5 {
  height: 41.6666666667%;
}
.grid-margin-y > .small-6 {
  height: 50%;
}
.grid-margin-y > .small-7 {
  height: 58.3333333333%;
}
.grid-margin-y > .small-8 {
  height: 66.6666666667%;
}
.grid-margin-y > .small-9 {
  height: 75%;
}
.grid-margin-y > .small-10 {
  height: 83.3333333333%;
}
.grid-margin-y > .small-11 {
  height: 91.6666666667%;
}
.grid-margin-y > .small-12 {
  height: 100%;
}
@media print, screen and (min-width: 43.75em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-3 {
    height: calc(25% - 3.125rem);
  }
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-6 {
    height: calc(50% - 3.125rem);
  }
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-9 {
    height: calc(75% - 3.125rem);
  }
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-y > .small-12 {
    height: calc(100% - 3.125rem);
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-2 {
    height: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-3 {
    height: calc(25% - 3.125rem);
  }
  .grid-margin-y > .medium-4 {
    height: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-5 {
    height: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-6 {
    height: calc(50% - 3.125rem);
  }
  .grid-margin-y > .medium-7 {
    height: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-8 {
    height: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-9 {
    height: calc(75% - 3.125rem);
  }
  .grid-margin-y > .medium-10 {
    height: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-y > .medium-11 {
    height: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-y > .medium-12 {
    height: calc(100% - 3.125rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: calc(8.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-2 {
    height: calc(16.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-3 {
    height: calc(25% - 3.125rem);
  }
  .grid-margin-y > .large-4 {
    height: calc(33.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-5 {
    height: calc(41.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-6 {
    height: calc(50% - 3.125rem);
  }
  .grid-margin-y > .large-7 {
    height: calc(58.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-8 {
    height: calc(66.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-9 {
    height: calc(75% - 3.125rem);
  }
  .grid-margin-y > .large-10 {
    height: calc(83.3333333333% - 3.125rem);
  }
  .grid-margin-y > .large-11 {
    height: calc(91.6666666667% - 3.125rem);
  }
  .grid-margin-y > .large-12 {
    height: calc(100% - 3.125rem);
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 0);
}
@media print, screen and (min-width: 43.75em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 3.125rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 3.125rem);
  }
}

@media print, screen and (min-width: 43.75em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 3.125rem);
  }
}
@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 3.125rem);
  }
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: 600;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "FrutigerNext", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: inherit;
  text-rendering: optimizeLegibility;
}
h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: rgb(77, 78, 76);
}

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 43.75em) {
  h1, .h1 {
    font-size: 3rem;
  }
  h2, .h2 {
    font-size: 2.5rem;
  }
  h3, .h3 {
    font-size: 1.9375rem;
  }
  h4, .h4 {
    font-size: 1.5625rem;
  }
  h5, .h5 {
    font-size: 1.25rem;
  }
  h6, .h6 {
    font-size: 1rem;
  }
}
a {
  line-height: inherit;
  color: rgb(218, 50, 42);
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #bf2821;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid rgb(77, 78, 76);
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: 600;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid rgb(77, 78, 76);
}
blockquote, blockquote p {
  line-height: 1.6;
  color: rgb(64, 64, 64);
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: rgb(64, 64, 64);
}
cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid rgb(77, 78, 76);
  background-color: rgb(224, 224, 224);
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 500;
  color: #000;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: rgb(224, 224, 224);
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.4;
  color: rgb(64, 64, 64);
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 43.75em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid rgb(64, 64, 64);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
[data-whatinput=mouse] .button {
  outline: 0;
}
.button:hover, .button:focus {
  background-color: #bc2821;
  color: rgb(255, 255, 255);
}
.button.tiny {
  font-size: 0.6rem;
}
.button.small {
  font-size: 0.75rem;
}
.button.large {
  font-size: 1.25rem;
}
.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.button.primary {
  background-color: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
.button.primary:hover, .button.primary:focus {
  background-color: #b1251f;
  color: rgb(255, 255, 255);
}
.button.secondary {
  background-color: #767676;
  color: #000;
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #000;
}
.button.success {
  background-color: #3adb76;
  color: #000;
}
.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #000;
}
.button.warning {
  background-color: #ffae00;
  color: #000;
}
.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #000;
}
.button.alert {
  background-color: #cc4b37;
  color: #000;
}
.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #000;
}
.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #000;
}
.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #000;
}
.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #000;
}
.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #000;
}
.button.hollow {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}
.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}
.button.hollow:hover, .button.hollow:focus {
  border-color: #6f1713;
  color: #6f1713;
}
.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.hollow.primary {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #6f1713;
  color: #6f1713;
}
.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}
.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}
.button.clear:hover, .button.clear:focus {
  border-color: #6f1713;
  color: #6f1713;
}
.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}
.button.clear.primary {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #6f1713;
  color: #6f1713;
}
.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}
.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}
.button.clear.secondary {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}
.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}
.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}
.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}
.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: rgb(255, 255, 255) transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}
.button.dropdown.hollow::after {
  border-top-color: rgb(218, 50, 42);
}
.button.dropdown.hollow.primary::after {
  border-top-color: rgb(218, 50, 42);
}
.button.dropdown.hollow.secondary::after {
  border-top-color: #767676;
}
.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}
.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}
.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}
.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid rgb(77, 78, 76);
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid rgb(64, 64, 64);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(77, 78, 76);
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: rgb(77, 78, 76);
}
input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: rgb(224, 224, 224);
  cursor: not-allowed;
}

[type=submit],
[type=button] {
  appearance: none;
  border-radius: 0;
}

input[type=search] {
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 1rem;
}

[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
[type=checkbox] + label[for],
[type=radio] + label[for] {
  cursor: pointer;
}

label > [type=checkbox],
label > [type=radio] {
  margin-right: 0.5rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.8;
  color: #000;
}
label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}
.input-group > :first-child {
  border-radius: 0 0 0 0;
}
.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid rgb(77, 78, 76);
  background: rgb(224, 224, 224);
  color: #000;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid rgb(77, 78, 76);
}
.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid rgb(77, 78, 76);
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2864, 64, 64%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select:focus {
  outline: none;
  border: 1px solid rgb(64, 64, 64);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgb(77, 78, 76);
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  background-color: rgb(224, 224, 224);
  cursor: not-allowed;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #cc4b37;
}
.form-error.is-visible {
  display: block;
}

.accordion {
  margin-left: 0;
  background: rgb(255, 255, 255);
  list-style-type: none;
}
.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}
.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid rgb(224, 224, 224);
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: rgb(218, 50, 42);
}
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid rgb(224, 224, 224);
  border-radius: 0 0 0 0;
}
.accordion-title:hover, .accordion-title:focus {
  background-color: rgb(224, 224, 224);
}
.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}
.is-active > .accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid rgb(224, 224, 224);
  border-bottom: 0;
  background-color: rgb(255, 255, 255);
  color: rgb(64, 64, 64);
}
:last-child > .accordion-content:last-child {
  border-bottom: 1px solid rgb(224, 224, 224);
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}
.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.breadcrumbs::after {
  clear: both;
}
.breadcrumbs li {
  float: left;
  font-size: 0.6875rem;
  color: #000;
  cursor: default;
  text-transform: uppercase;
}
.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: rgb(77, 78, 76);
}
.breadcrumbs a {
  color: rgb(218, 50, 42);
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs .disabled {
  color: rgb(77, 78, 76);
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
.button-group::before, .button-group::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.button-group::after {
  clear: both;
}
.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}
.button-group .button:last-child {
  margin-right: 0;
}
.button-group.tiny .button {
  font-size: 0.6rem;
}
.button-group.small .button {
  font-size: 0.75rem;
}
.button-group.large .button {
  font-size: 1.25rem;
}
.button-group.expanded .button {
  flex: 1 1 0px;
}
.button-group.primary .button {
  background-color: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #b1251f;
  color: rgb(255, 255, 255);
}
.button-group.secondary .button {
  background-color: #767676;
  color: #000;
}
.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #5e5e5e;
  color: #000;
}
.button-group.success .button {
  background-color: #3adb76;
  color: #000;
}
.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #000;
}
.button-group.warning .button {
  background-color: #ffae00;
  color: #000;
}
.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #000;
}
.button-group.alert .button {
  background-color: #cc4b37;
  color: #000;
}
.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #000;
}
.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}
.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}
.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 43.75em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 43.6875em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.close-button {
  position: absolute;
  color: rgb(64, 64, 64);
  cursor: pointer;
}
[data-whatinput=mouse] .close-button {
  outline: 0;
}
.close-button:hover, .close-button:focus {
  color: #000;
}
.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}
.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
[data-whatinput=mouse] .menu li {
  outline: 0;
}
.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}
.menu input {
  display: inline-block;
}
.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}
.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}
.menu.expanded li {
  flex: 1 1 0px;
}
.menu.simple {
  align-items: center;
}
.menu.simple li + li {
  margin-left: 1rem;
}
.menu.simple a {
  padding: 0;
}
@media print, screen and (min-width: 43.75em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}
.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}
.menu.icons a {
  display: flex;
}
.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}
.menu.icon-left li a {
  flex-flow: row nowrap;
}
.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}
.menu.icon-right li a {
  flex-flow: row nowrap;
}
.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}
.menu.icon-top li a {
  flex-flow: column nowrap;
}
.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu.icon-bottom li a {
  flex-flow: column nowrap;
}
.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu .is-active > a {
  background: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
.menu .active > a {
  background: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
}
.menu.align-left {
  justify-content: flex-start;
}
.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}
.menu.align-right li .submenu li {
  justify-content: flex-start;
}
.menu.align-right.vertical li {
  display: block;
  text-align: right;
}
.menu.align-right.vertical li .submenu li {
  text-align: right;
}
.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}
.menu.align-center li {
  display: flex;
  justify-content: center;
}
.menu.align-center li .submenu li {
  justify-content: flex-start;
}
.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}
.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}
.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid rgb(77, 78, 76);
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  font-size: 1rem;
}
.dropdown-pane.is-opening {
  display: block;
}
.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}
.media-object img {
  max-width: none;
}
@media screen and (max-width: 43.6875em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 43.6875em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}
.media-object-section:first-child {
  padding-right: 1rem;
}
.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}
.media-object-section > :last-child {
  margin-bottom: 0;
}
.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}
.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}
.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: rgb(224, 224, 224);
}
[data-whatinput=mouse] .off-canvas {
  outline: 0;
}
.off-canvas.is-transition-push {
  z-index: 12;
}
.off-canvas.is-closed {
  visibility: hidden;
}
.off-canvas.is-transition-overlap {
  z-index: 13;
}
.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}
.off-canvas.is-open {
  transform: translate(0, 0);
}
.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: rgb(224, 224, 224);
}
[data-whatinput=mouse] .off-canvas-absolute {
  outline: 0;
}
.off-canvas-absolute.is-transition-push {
  z-index: 12;
}
.off-canvas-absolute.is-closed {
  visibility: hidden;
}
.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}
.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}
.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
@media screen and (min-width: 15.625em) {
  .position-left {
    transform: translateX(-);
  }
}
@media screen and (min-width: 15.625em) {
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-);
  }
}
.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media screen and (min-width: 15.625em) {
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX();
  }
}

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(0, 0, 0, 0.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
}
@media screen and (min-width: 15.625em) {
  .position-right {
    transform: translateX();
  }
}
@media screen and (min-width: 15.625em) {
  .off-canvas-content .off-canvas.position-right {
    transform: translateX();
  }
}
.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media screen and (min-width: 15.625em) {
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-);
  }
}

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(0, 0, 0, 0.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}
@media screen and (min-width: 15.625em) {
  .position-top {
    transform: translateY(-);
  }
}
@media screen and (min-width: 15.625em) {
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-);
  }
}
.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media screen and (min-width: 15.625em) {
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY();
  }
}

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(0, 0, 0, 0.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}
@media screen and (min-width: 15.625em) {
  .position-bottom {
    transform: translateY();
  }
}
@media screen and (min-width: 15.625em) {
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY();
  }
}
.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

@media screen and (min-width: 15.625em) {
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-);
  }
}

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(0, 0, 0, 0.25);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}
.off-canvas-content.has-transition-push {
  transform: translate(0, 0);
}
.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 43.75em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 43.75em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }
}
@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }
}
.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 43.6875em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 43.75em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 43.75em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 43.6875em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 43.75em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 43.6875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 43.75em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}
@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}
.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 43.75em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  transition-duration: 750ms !important;
}

.fast {
  transition-duration: 250ms !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  transition-delay: 300ms !important;
}

.long-delay {
  transition-delay: 700ms !important;
}

.shake {
  animation-name: shake-7;
}
@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}

.spin-cw {
  animation-name: spin-cw-1turn;
}
@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }
  100% {
    transform: rotate(0);
  }
}

.spin-ccw {
  animation-name: spin-cw-1turn;
}
@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

.wiggle {
  animation-name: wiggle-7deg;
}
@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }
  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: 750ms !important;
}

.fast {
  animation-duration: 250ms !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  animation-delay: 300ms !important;
}

.long-delay {
  animation-delay: 700ms !important;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 10;
  display: block;
  height: 30px;
  width: 40px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  background-size: cover;
  color: transparent;
  bottom: -60px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  right: 100px;
}
.slick-prev:before {
  content: "";
  display: block;
  background-image: url("/assets/img/arrow_left.svg");
  width: 40px;
  height: 30px;
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 30px;
}
.slick-next:before {
  content: "";
  display: block;
  background-image: url("/assets/img/arrow_right.svg");
  width: 40px;
  height: 30px;
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: relative;
  top: 25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.7;
}

#header-main {
  height: 110px;
  width: 100%;
  position: fixed;
  left: 0;
  background: #000;
  z-index: 999;
  transition: all 0.2s;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 75rem) {
  #header-main {
    left: inherit;
  }
}
#header-main .header-main-wrapper {
  width: 75rem;
  margin: 0 auto;
  height: 80px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
}
@media screen and (max-width: 75rem) {
  #header-main .header-main-wrapper {
    width: 100%;
  }
}
#header-main .header-main-wrapper .off-canvas-btn-open {
  display: none;
  width: 36px;
  height: 36px;
  background: url("/assets/img/icon_menu_open.svg") no-repeat;
  background-size: cover;
}
#header-main .header-main-wrapper .off-canvas-btn-open:focus {
  border: 0 none;
  outline: none;
}
@media screen and (max-width: 63.9375em) {
  #header-main .header-main-wrapper .off-canvas-btn-open {
    display: block;
  }
}
#header-main .header-main-wrapper .logo a {
  display: block;
  height: 48px;
  width: 200px;
  transition: all 0.2s;
  background: url("/assets/img/logo_head_large.svg") no-repeat;
  background-size: cover;
}
@media screen and (max-width: 43.6875em) {
  #header-main .header-main-wrapper .logo a {
    height: 40px;
    width: 40px;
    background-image: url("/assets/img/logo_head_small.svg");
  }
}
#header-main .header-main-wrapper .navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /*.search {
    position: relative;

    span {
      display: block;
      width: 36px;
      height: 36px;
      background: url("/assets/img/icon_search_open.svg") no-repeat;
      opacity: .9;
      transition: all 0.2s;

      &.active {
        background: url("/assets/img/icon_search_close.svg") no-repeat;
      }

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }

    .searchinput {
      display: none;
      position: absolute;
      height: 50px;
      width: 220px;
      background: $primary-color;
      top: 58px;
      right: 0;
      justify-content: center;
      align-items: center;

      &.active {
        display: flex;
      }

      form {
        input {
          height: inherit;
          margin: 0;
          border: 0;
          box-shadow: none;
          background: $primary-color;
          color: $white;
          font-weight: 500;

          &::placeholder {
            color: $white;
            font-weight: 500;
          }
        }
      }
    }
  }*/
}
@media screen and (max-width: 63.9375em) {
  #header-main .header-main-wrapper .navigation {
    display: none;
  }
}
#header-main .header-main-wrapper .navigation .nav-main ul {
  margin: 0;
  padding: 0;
}
#header-main .header-main-wrapper .navigation .nav-main ul li {
  display: inline-block;
  margin: 0 0 0 25px;
  padding: 0;
  position: relative;
}
@media screen and (max-width: 89.9375em) {
  #header-main .header-main-wrapper .navigation .nav-main ul li {
    margin: 0 0 0 20px;
  }
}
#header-main .header-main-wrapper .navigation .nav-main ul li:first-child {
  margin-left: 0;
}
#header-main .header-main-wrapper .navigation .nav-main ul li a {
  display: block;
  height: 80px;
  line-height: 80px;
  font-size: 27px;
  font-weight: 500;
  color: rgb(218, 50, 42);
  transition: all 0.2s;
}
@media screen and (max-width: 89.9375em) {
  #header-main .header-main-wrapper .navigation .nav-main ul li a {
    font-size: 22px;
  }
}
#header-main .header-main-wrapper .navigation .nav-main ul li a:hover {
  border-bottom: 5px solid rgb(218, 50, 42);
}
#header-main .header-main-wrapper .navigation .nav-main ul li.active a {
  border-bottom: 5px solid rgb(218, 50, 42);
}
#header-main .header-main-wrapper .navigation .nav-main ul li.has-subnav a:hover {
  border: 0 none;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul {
  display: none;
  flex-direction: column;
  position: absolute;
  width: 260px;
  height: inherit;
  top: 80px;
  left: -15px;
  background: #000;
}
@media screen and (max-width: 1515px) {
  #header-main .header-main-wrapper .navigation .nav-main ul li ul {
    left: inherit;
    right: -15px;
  }
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li {
  transition: all 0.25s;
  width: 100%;
  padding: 0;
  margin: 0;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li:last-child {
  border-bottom: 4px solid rgb(218, 50, 42);
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li a {
  padding: 7px 15px;
  height: inherit;
  color: rgb(255, 255, 255);
  border: 0 none;
  line-height: 24px;
  font-size: 20px;
  font-weight: 300;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li a:hover {
  border: 0 none;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li:first-child a {
  padding-top: 14px;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li:last-child a {
  padding-bottom: 14px;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li:hover {
  transition: all 0.25s;
}
#header-main .header-main-wrapper .navigation .nav-main ul li ul li:hover a {
  color: rgb(218, 50, 42);
}
#header-main .header-main-wrapper .navigation .nav-main ul li:hover ul {
  display: flex;
}
#header-main.shrink {
  height: 60px;
}
#header-main.shrink .header-main-wrapper {
  height: 60px;
  /*.search {
    .searchinput {
      top: 48px;
    }
  }*/
}
#header-main.shrink .header-main-wrapper .navigation .nav-main ul li a {
  height: 60px;
  line-height: 60px;
}
#header-main.shrink .header-main-wrapper .logo img {
  height: 40px;
  width: auto;
}
@media screen and (max-width: 63.9375em) {
  #header-main {
    height: 60px;
    transition: all 0.2s;
  }
  #header-main .header-main-wrapper {
    height: 60px;
    transition: all 0.2s;
  }
  #header-main .header-main-wrapper .logo {
    transition: all 0.2s;
  }
  #header-main .header-main-wrapper .logo img {
    height: 40px;
    width: auto;
    transition: all 0.2s;
  }
}

.top-bar-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 75rem) {
  .top-bar-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .top-bar-wrapper {
    display: none;
  }
}

.top-bar {
  height: auto;
  display: flex;
  justify-content: flex-end;
  width: 75rem;
  padding: 0 20px;
  margin: 0 !important;
}
.top-bar ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.top-bar ul li {
  list-style: none;
  color: white;
  margin-right: 5px;
}
.top-bar ul li:last-of-type {
  margin-right: 0;
}
.top-bar ul li a {
  text-decoration: none;
  color: white;
}
.top-bar ul li a:hover {
  color: rgb(218, 50, 42);
}
.top-bar ul li a.active {
  color: rgb(218, 50, 42);
}

.off-canvas {
  background: #000;
  display: none;
}
.off-canvas.is-open {
  display: block;
}
.off-canvas-header-main {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
  border-bottom: 1px solid rgb(77, 78, 76);
}
.off-canvas-header-main .off-canvas {
  /*&-searchinput {
    height: 36px;
    width: auto;
    background: $primary-color;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    &.active {
      display: flex;
    }

    form {
      input {
        height: inherit;
        margin: 0;
        border: 0;
        box-shadow: none;
        background: $primary-color;
        color: $white;
        font-weight: 500;
        padding-top: 7px;

        &::placeholder {
          color: $white;
          font-weight: 500;
        }
      }
    }
  }*/
}
.off-canvas-header-main .off-canvas-btn-close {
  display: block;
  padding-right: 36px;
  width: 36px;
  height: 36px;
  background: url("/assets/img/icon_menu_close.svg") no-repeat;
  background-size: cover;
}
.off-canvas-header-main .off-canvas-btn-close:focus {
  border: 0 none;
  outline: none;
}
.off-canvas-nav-main ul {
  margin: 0;
  padding: 0;
}
.off-canvas-nav-main ul li {
  display: block;
  list-style: none;
  padding: 0;
}
.off-canvas-nav-main ul li a {
  padding: 20px;
  display: block;
  line-height: 26px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 26px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  transition: all 0.2s;
  border-bottom: 1px solid rgb(77, 78, 76);
}
.off-canvas-nav-main ul li a:before {
  content: "#";
  display: inline;
  color: rgb(218, 50, 42);
  margin-right: 5px;
}
.off-canvas-nav-main ul li a:hover {
  color: rgb(218, 50, 42);
}
.off-canvas-nav-main ul li .active a {
  color: rgb(218, 50, 42);
}
.off-canvas-nav-main ul li ul {
  margin: 0 !important;
}
.off-canvas-nav-main ul li ul li a {
  font-size: 22px;
  line-height: 22px;
  padding: 12px 20px 12px 37px;
  border: 0 none;
}
.off-canvas-nav-main ul li ul li a:before {
  display: none;
}
.off-canvas-nav-main ul li ul li:first-child a {
  padding: 20px 20px 5px 37px;
}
.off-canvas-nav-main ul li ul li:last-child a {
  border-bottom: 1px solid rgb(77, 78, 76);
  padding: 12px 20px 20px 37px;
}
.off-canvas-nav-second ul {
  margin: 0;
  padding: 0;
}
.off-canvas-nav-second ul li {
  display: block;
  list-style: none;
  padding: 0;
}
.off-canvas-nav-second ul li a {
  padding: 14px 20px 10px;
  display: block;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(224, 224, 224);
  text-transform: uppercase;
  transition: all 0.2s;
  border-bottom: 1px solid rgba(245, 245, 245, 0.4);
}
.off-canvas-nav-second ul li a:hover {
  color: rgb(255, 255, 255);
}
.off-canvas-nav-second ul li .active a {
  color: rgb(218, 50, 42);
}
.off-canvas-logo {
  display: flex;
  justify-content: center;
  padding: 10px 0 0;
  opacity: 0.25;
}
.off-canvas-logo img {
  display: block;
  width: 120px;
  height: auto;
}
.off-canvas-copyright {
  display: flex;
  justify-content: center;
  padding: 0 0 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: inherit;
  color: rgba(245, 245, 245, 0.4);
}
.off-canvas .top-bar {
  width: auto;
  margin: 0;
}

#footer-main .footer-nav-wrapper {
  background: #000;
  padding: 90px 0 60px;
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-nav-wrapper {
    padding: 40px 0 20px;
  }
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-nav-wrapper {
    padding: 0;
  }
}
#footer-main .footer-nav-wrapper .footer-nav {
  width: 75rem;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}
#footer-main .footer-nav-wrapper .footer-nav::before, #footer-main .footer-nav-wrapper .footer-nav::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
#footer-main .footer-nav-wrapper .footer-nav::after {
  clear: both;
}
@media screen and (max-width: 75rem) {
  #footer-main .footer-nav-wrapper .footer-nav {
    width: 100%;
  }
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-nav-wrapper .footer-nav {
    padding: 0;
  }
}
#footer-main .footer-nav-wrapper .footer-nav ul {
  margin: 0;
}
#footer-main .footer-nav-wrapper .footer-nav ul li {
  width: 25%;
  float: left;
  list-style: none;
  margin-bottom: 80px;
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-nav-wrapper .footer-nav ul li {
    width: 100%;
  }
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-nav-wrapper .footer-nav ul li {
    margin: 0;
  }
}
#footer-main .footer-nav-wrapper .footer-nav ul li a {
  height: 65px;
  display: block;
  margin-bottom: 10px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 34px;
  color: rgb(255, 255, 255);
  word-wrap: break-word;
}
#footer-main .footer-nav-wrapper .footer-nav ul li a:before {
  content: "#";
  display: inline;
  color: rgb(218, 50, 42);
  margin-right: 5px;
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-nav-wrapper .footer-nav ul li a {
    margin: 0;
    line-height: 60px;
    font-size: 22px;
    height: inherit;
  }
}
#footer-main .footer-nav-wrapper .footer-nav ul li a:hover {
  color: rgb(218, 50, 42);
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-nav-wrapper .footer-nav ul li a {
    font-size: 22px;
  }
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-nav-wrapper .footer-nav ul li a {
    display: block;
    border-bottom: 1px solid rgba(245, 245, 245, 0.4);
    padding: 0 20px;
    margin: 0;
  }
}
#footer-main .footer-nav-wrapper .footer-nav ul li ul {
  display: block;
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-nav-wrapper .footer-nav ul li ul {
    display: none;
  }
}
#footer-main .footer-nav-wrapper .footer-nav ul li ul li {
  width: inherit;
  float: inherit;
  margin-bottom: 3px;
}
#footer-main .footer-nav-wrapper .footer-nav ul li ul li a {
  height: inherit;
  margin-bottom: inherit;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}
#footer-main .footer-nav-wrapper .footer-nav ul li ul li a:before {
  display: none;
}
#footer-main .footer-bottom-wrapper {
  height: 60px;
  background: rgb(255, 255, 255);
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-bottom-wrapper {
    height: auto;
  }
}
#footer-main .footer-bottom-wrapper .footer-bottom {
  width: 75rem;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
@media screen and (max-width: 75rem) {
  #footer-main .footer-bottom-wrapper .footer-bottom {
    width: 100%;
  }
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-bottom-wrapper .footer-bottom {
    height: auto;
    padding: 40px 0;
    flex-direction: column;
  }
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-logo img {
  height: 36px;
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-bottom-wrapper .footer-bottom .footer-logo {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 63.9375em) {
  #footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav {
    margin-bottom: 20px;
  }
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul {
  margin: 0;
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul li {
  display: inline;
  margin-right: 30px;
  line-height: inherit;
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul li {
    display: block;
    text-align: center;
    margin: 0 0 20px;
  }
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul li:last-child {
  margin: 0;
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul li a {
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: rgb(218, 50, 42);
  line-height: inherit;
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul li a:hover {
  color: #000;
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-bottom-wrapper .footer-bottom .footer-legal-nav ul li a {
    text-align: center;
  }
}
#footer-main .footer-bottom-wrapper .footer-bottom .footer-copyright {
  font-size: 20px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  line-height: inherit;
  color: rgb(218, 50, 42);
}
@media screen and (max-width: 43.6875em) {
  #footer-main .footer-bottom-wrapper .footer-bottom .footer-copyright {
    color: #000;
  }
}

@font-face {
  font-family: "Impact";
  font-weight: 300;
  src: url("/assets/font/692833/a41e1b81-3206-49c5-bd2a-d2a6727cf783.eot?#iefix");
  src: url("/assets/font/692833/a41e1b81-3206-49c5-bd2a-d2a6727cf783.eot?#iefix") format("eot"), url("/assets/font/692833/eb7104aa-15d7-4190-85bb-6fe4434e602d.woff2") format("woff2"), url("/assets/font/692833/320e46b1-f8bc-48fd-b1c1-026806a249f8.woff") format("woff"), url("/assets/font/692833/29a2eb3d-ef98-429e-a6c3-17742370d5ef.ttf") format("truetype");
}
@font-face {
  font-family: "FrutigerNext";
  font-weight: 300;
  src: url("/assets/font/722217/4cef6d85-d22a-4541-b469-da13751862aa.eot?#iefix");
  src: url("/assets/font/722217/4cef6d85-d22a-4541-b469-da13751862aa.eot?#iefix") format("eot"), url("/assets/font/722217/67f049a9-98c0-4488-a4e4-0be6cda6f7de.woff2") format("woff2"), url("/assets/font/722217/d74de079-587d-4049-9cca-50ba02a536f9.woff") format("woff"), url("/assets/font/722217/07749504-e72d-4fc9-a58d-5b853dd51fc7.ttf") format("truetype");
}
@font-face {
  font-family: "FrutigerNext";
  font-weight: 500;
  src: url("/assets/font/722169/ce9ebe9b-6684-4783-8760-f3a20a0c52f0.eot?#iefix");
  src: url("/assets/font/722169/ce9ebe9b-6684-4783-8760-f3a20a0c52f0.eot?#iefix") format("eot"), url("/assets/font/722169/983d9248-775f-41ea-ad59-cee5bdbb6147.woff2") format("woff2"), url("/assets/font/722169/5f3f5b96-b77d-497d-a916-d483bc9c6c3f.woff") format("woff"), url("/assets/font/722169/209c5dbd-a0e8-4fdf-ada6-50628360eefd.ttf") format("truetype");
}
@font-face {
  font-family: "FrutigerNextCondens";
  font-weight: 300;
  src: url("/assets/font/722190/3aabd8f8-de16-4c1d-94fa-1d85cdeca8b5.eot?#iefix");
  src: url("/assets/font/722190/3aabd8f8-de16-4c1d-94fa-1d85cdeca8b5.eot?#iefix") format("eot"), url("/assets/font/722190/33de3f05-9ad2-4aa8-8305-953d212ed871.woff2") format("woff2"), url("/assets/font/722190/f5206e18-da88-476d-a17e-ac9c580f00c1.woff") format("woff"), url("/assets/font/722190/084bc02f-68ac-47c0-8102-0b1b53e8d4ac.ttf") format("truetype");
}
@font-face {
  font-family: "FrutigerNextCondens";
  font-weight: 500;
  src: url("/assets/font/722178/497c3588-4b93-4f85-bcbc-52af2bdf03df.eot?#iefix");
  src: url("/assets/font/722178/497c3588-4b93-4f85-bcbc-52af2bdf03df.eot?#iefix") format("eot"), url("/assets/font/722178/46195450-8eef-4511-ac19-062ed9a6f1a0.woff2") format("woff2"), url("/assets/font/722178/78cb2356-22e7-4eae-bbb7-f947f2384d4b.woff") format("woff"), url("/assets/font/722178/ac1fbae2-c358-43bd-8789-791cd09a114d.ttf") format("truetype");
}
body {
  background: rgb(255, 255, 255);
  font-weight: 300;
  scroll-behavior: smooth;
}

b,
strong {
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Impact", Helvetica, Arial, sans-serif;
  color: rgb(218, 50, 42);
  hyphens: auto;
}

h1,
h2 {
  font-size: 2.5rem;
}

@media screen and (max-width: 63.9375em) {
  h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 43.6875em) {
  h1 {
    font-size: 28px;
  }
}

@media screen and (max-width: 63.9375em) {
  h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 43.6875em) {
  h2 {
    font-size: 24px;
  }
}

.slick-slide:focus {
  outline: none;
}

#main-wrapper {
  min-height: 300px;
  padding-top: 110px;
}
@media screen and (max-width: 63.9375em) {
  #main-wrapper {
    padding-top: 60px;
  }
}

.page-header {
  width: 75rem;
  margin: 0 auto;
  padding-top: 50px;
}
@media screen and (max-width: 75rem) {
  .page-header {
    width: 100%;
  }
}
@media screen and (max-width: 63.9375em) {
  .page-header {
    padding-top: 20px;
  }
}
.page-header .breadcrumb ul {
  margin: 0;
}
.page-header .breadcrumb ul li {
  display: inline-block;
  color: #000;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
.page-header .breadcrumb ul li a,
.page-header .breadcrumb ul li h1 {
  font-size: 20px;
  color: #000;
}
.page-header .breadcrumb ul li a:hover,
.page-header .breadcrumb ul li h1:hover {
  color: rgb(218, 50, 42);
}
@media screen and (max-width: 63.9375em) {
  .page-header .breadcrumb ul li a,
  .page-header .breadcrumb ul li h1 {
    font-size: 18px;
  }
}
.page-header .breadcrumb ul li:after {
  content: "/";
  display: inline-block;
  font-size: 20px;
}
.page-header .breadcrumb ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.page-header .breadcrumb ul li:last-child:after {
  display: none;
}
.page-header .breadcrumb ul li:last-child {
  display: block;
  font-size: 2.5rem;
  color: rgb(218, 50, 42);
  line-height: 2.5rem;
}
.page-header .breadcrumb ul li:last-child a,
.page-header .breadcrumb ul li:last-child h1 {
  display: block;
  font-size: 30px;
  color: rgb(218, 50, 42);
  line-height: 30px;
}

.content-wrapper {
  /*&.COLOR {
    background: COLOR;
  }*/
}
.content-wrapper .content-wrapper-box {
  width: 75rem;
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
}
@media screen and (max-width: 75rem) {
  .content-wrapper .content-wrapper-box {
    width: 100%;
  }
}
.content-wrapper.fullwidth .content-wrapper-box {
  width: 100%;
  padding: 0;
}
.content-wrapper.fullwidth-align-left .content-wrapper-box {
  width: 97.6rem;
  position: relative;
}
@media screen and (max-width: 1602px) {
  .content-wrapper.fullwidth-align-left .content-wrapper-box {
    width: 100%;
    padding-right: 40px;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-wrapper.fullwidth-align-left .content-wrapper-box {
    padding: 0 40px;
  }
}
.content-wrapper.fullwidth-align-left .content-wrapper-box .justify-center {
  padding: 100px 0;
}
@media screen and (max-width: 63.9375em) {
  .content-wrapper.fullwidth-align-left .content-wrapper-box .justify-center {
    padding: 50px 0;
  }
  .content-wrapper.fullwidth-align-left .content-wrapper-box .justify-center:first-child {
    margin-bottom: 50px;
  }
}
.content-wrapper.align-right, .content-wrapper.align-left {
  width: 95%;
  position: relative;
}
@media screen and (max-width: 1450px) {
  .content-wrapper.align-right, .content-wrapper.align-left {
    width: 100%;
  }
}
.content-wrapper.align-right .content-wrapper-box, .content-wrapper.align-left .content-wrapper-box {
  width: 75rem;
  position: relative;
  left: calc(51% - 37.5rem);
  padding: 0 50px;
}
@media screen and (max-width: 1450px) {
  .content-wrapper.align-right .content-wrapper-box, .content-wrapper.align-left .content-wrapper-box {
    left: inherit;
    margin: 0 auto;
  }
}
@media screen and (max-width: 75rem) {
  .content-wrapper.align-right .content-wrapper-box, .content-wrapper.align-left .content-wrapper-box {
    width: 100%;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-wrapper.align-right .content-wrapper-box, .content-wrapper.align-left .content-wrapper-box {
    padding: 0 40px;
  }
}
.content-wrapper.align-left {
  left: 0;
}
.content-wrapper.align-right {
  left: 5%;
}
.content-wrapper.align-right .content-wrapper-box {
  left: calc(46% - 37.9rem);
}
@media screen and (max-width: 1450px) {
  .content-wrapper.align-right .content-wrapper-box {
    left: inherit;
  }
}
@media screen and (max-width: 1450px) {
  .content-wrapper.align-right {
    left: inherit;
  }
}
.content-wrapper.inner-space-vert {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (max-width: 63.9375em) {
  .content-wrapper.inner-space-vert {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-wrapper.inner-space-vert {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.content-wrapper.outer-space-vert {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 63.9375em) {
  .content-wrapper.outer-space-vert {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-wrapper.outer-space-vert {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.content-wrapper.inner-space-hor {
  padding-left: 5%;
  padding-right: 5%;
}
.content-wrapper.black {
  background: #000;
  position: relative;
}
.content-wrapper.light-grey {
  background: #F4F4F4;
  position: relative;
}

.product-detail-start .product-detail-slider-main {
  margin-bottom: 20px;
}
@media screen and (max-width: 63.9375em) {
  .product-detail-start .product-detail-slider-nav {
    margin-bottom: 40px;
  }
}
.product-detail-start .product-detail-slider-nav .slick-slide {
  border: 1px solid rgb(224, 224, 224);
  margin-right: 20px;
}
.product-detail-start .product-detail-slider-nav .slick-slide.slick-current {
  border-color: #000;
}

.parallax {
  height: 300px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin-bottom: 40px;
}
@media screen and (max-width: 43.6875em) {
  .parallax {
    background: inherit;
    background-size: cover;
    background-position: center;
  }
}

.parallax-1 {
  background-image: url("/assets/img/_X0A2930ret.jpg");
}

.headline-banner {
  text-align: center;
  position: relative;
}
@media screen and (max-width: 43.6875em) {
  .headline-banner {
    margin-bottom: 20px;
  }
}
.headline-banner span {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #000;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
.headline-banner h1,
.headline-banner h2,
.headline-banner h3,
.headline-banner h4,
.headline-banner h5,
.headline-banner h6 {
  background: rgb(255, 255, 255);
  position: relative;
  z-index: 10;
  hyphens: auto;
  display: block;
  max-width: 100%;
  width: max-content;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 34px;
  line-height: 44px;
  color: rgb(218, 50, 42);
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (max-width: 43.6875em) {
  .headline-banner h1,
  .headline-banner h2,
  .headline-banner h3,
  .headline-banner h4,
  .headline-banner h5,
  .headline-banner h6 {
    padding: 0;
  }
}
.headline-banner:before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(224, 224, 224);
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 9;
}

.content-wrapper.light-grey h1,
.content-wrapper.light-grey h2,
.content-wrapper.light-grey h3,
.content-wrapper.light-grey h4,
.content-wrapper.light-grey h5,
.content-wrapper.light-grey h6 {
  background: rgb(244, 244, 244);
}

.trenner {
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(224, 224, 224);
}

.content-fullwidth-5050 {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 43.6875em) {
  .content-fullwidth-5050 {
    display: block;
    border-bottom: 0;
  }
}
.content-fullwidth-5050 .left,
.content-fullwidth-5050 .right {
  width: 50%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 63.9375em) {
  .content-fullwidth-5050 .left,
  .content-fullwidth-5050 .right {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-fullwidth-5050 .left,
  .content-fullwidth-5050 .right {
    width: 100%;
  }
}
.content-fullwidth-5050 .left {
  border-right: 2px solid rgb(224, 224, 224);
  padding-left: calc(50% - 37.5rem + 20px);
  padding-right: 40px;
}
@media screen and (max-width: 75rem) {
  .content-fullwidth-5050 .left {
    padding-left: 20px;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-fullwidth-5050 .left {
    border-right: 0 none;
    border-bottom: 2px solid rgb(224, 224, 224);
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}
.content-fullwidth-5050 .right {
  padding-right: calc(50% - 37.5rem + 20px);
  padding-left: 40px;
}
@media screen and (max-width: 75rem) {
  .content-fullwidth-5050 .right {
    padding-right: 20px;
  }
}
@media screen and (max-width: 43.6875em) {
  .content-fullwidth-5050 .right {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
  }
}

.product-info-list ul {
  margin: 0;
  padding: 0 0 0 15px;
}
.product-info-list ul li {
  position: relative;
  display: block;
  list-style: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  margin-right: 5px;
  margin-bottom: 10px;
}
.product-info-list ul li:before {
  content: "#";
  position: absolute;
  top: 2px;
  left: -15px;
  display: inline;
  font-size: 16px;
  font-weight: 500;
  color: rgb(218, 50, 42);
}
.product-info-list ul li a {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;
}
.product-info-list .button {
  margin-top: 40px;
  padding: 20px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 26px;
  line-height: 26px;
  color: rgb(255, 255, 255);
  background: #000;
}
@media screen and (max-width: 63.9375em) {
  .product-info-list .button {
    width: 100%;
  }
}
.product-info-list .button:hover {
  color: rgb(218, 50, 42);
}
.product-info-list.-lite ul li {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.product-info-colorvariants .title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}
.product-info-colorvariants ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
}
@media screen and (max-width: 43.6875em) {
  .product-info-colorvariants ul {
    flex-direction: column;
  }
}
.product-info-colorvariants ul li {
  width: calc(33% - 8px);
  margin-right: 12px;
  margin-bottom: 12px;
  list-style: none;
}
.product-info-colorvariants ul li:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 43.6875em) {
  .product-info-colorvariants ul li {
    width: 100%;
    margin: 0 0 20px;
  }
}
.product-info-colorvariants ul li ul {
  display: block;
  margin-bottom: 20px;
}
.product-info-colorvariants ul li ul li {
  display: inline;
  list-style: none;
  font-size: 13px;
  color: #000;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 13px;
}
.product-info-colorvariants ul li ul li:before {
  content: "#";
  display: inline;
  font-size: 13px;
  font-weight: 500;
  color: rgb(218, 50, 42);
  margin-right: 4px;
  line-height: 13px;
}

.product-info-facts {
  background: rgb(255, 255, 255);
}
.product-info-facts ul {
  margin: 0;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 75rem) {
  .product-info-facts ul {
    flex-wrap: wrap;
  }
}
.product-info-facts ul li {
  width: 100px;
  display: flex;
  flex-direction: column;
  border-left: 2px solid rgb(224, 224, 224);
  padding: 5px 15px;
}
.product-info-facts ul li:last-child {
  border-right: 2px solid rgb(224, 224, 224);
}
@media screen and (max-width: 75rem) {
  .product-info-facts ul li:last-child {
    width: 33.5%;
  }
}
@media screen and (max-width: 63.9375em) {
  .product-info-facts ul li:last-child {
    width: 50.25%;
  }
}
@media screen and (max-width: 43.6875em) {
  .product-info-facts ul li:last-child {
    width: 100%;
    border-bottom: 0 none;
    border-right: 0 none;
  }
}
@media screen and (max-width: 75rem) {
  .product-info-facts ul li {
    width: 33.33%;
    padding: 15px;
    border-bottom: 2px solid rgb(224, 224, 224);
    flex-direction: row;
  }
  .product-info-facts ul li:nth-child(3n) {
    border-right: 2px solid rgb(224, 224, 224);
  }
}
@media screen and (max-width: 63.9375em) {
  .product-info-facts ul li {
    width: 50%;
    border-bottom: 2px solid rgb(224, 224, 224);
  }
  .product-info-facts ul li:nth-child(3n) {
    border-bottom: 2px solid rgb(224, 224, 224);
    border-right: 0 none;
  }
  .product-info-facts ul li:nth-child(2n) {
    border-bottom: 2px solid rgb(224, 224, 224);
    border-right: 2px solid rgb(224, 224, 224);
  }
}
@media screen and (max-width: 43.6875em) {
  .product-info-facts ul li {
    width: 100%;
    border: 0 none;
    border-bottom: 2px solid rgb(224, 224, 224);
  }
  .product-info-facts ul li:nth-child(2n) {
    border: 0 none;
    border-bottom: 2px solid rgb(224, 224, 224);
  }
}
.product-info-facts ul li .facts-label {
  width: 69px;
  height: 69px;
  margin-bottom: 20px;
  font-size: 14px;
  word-wrap: break-word;
}
@media screen and (max-width: 75rem) {
  .product-info-facts ul li .facts-label {
    width: 85px;
    height: 85px;
    margin-right: 15px;
    margin-bottom: 0;
  }
}
.product-info-facts ul li .facts-label.icon-label:after {
  width: 69px;
  height: 69px;
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 75rem) {
  .product-info-facts ul li .facts-label.icon-label:after {
    width: 85px;
    height: 85px;
  }
}
.product-info-facts ul li .facts-label.icon-label-allergie:after {
  background-image: url("/assets/img/icon_allergy_free.svg");
}
.product-info-facts ul li .facts-label.icon-label-helm:after {
  background-image: url("/assets/img/icon_with_helmet.svg");
}
.product-info-facts ul li .facts-label.icon-label-gehoerschutz:after {
  background-image: url("/assets/img/icon_with_ear_protector.svg");
}
.product-info-facts ul li .facts-label.icon-label-elektro:after {
  background-image: url("/assets/img/icon_electrical_work.svg");
}
.product-info-facts ul li .facts-label.icon-label-nasenpad:after {
  background-image: url("/assets/img/icon_soft_nosepad.svg");
}
.product-info-facts ul li .facts-label.icon-label-ergonomisch:after {
  background-image: url("/assets/img/icon_ergonomic.svg");
}
.product-info-facts ul li .facts-label.icon-label-DIN-166:after {
  background-image: url("/assets/img/icon_din_en_166.svg");
}
.product-info-facts ul li .facts-label.icon-label-verglasbar:after {
  background-image: url("/assets/img/icon_dpt.svg");
}
.product-info-facts ul li .facts-value {
  word-wrap: break-word;
  font-size: 12px;
}
@media screen and (max-width: 75rem) {
  .product-info-facts ul li .facts-value {
    display: flex;
    align-items: center;
  }
}

.similar-products {
  background: rgb(244, 244, 244);
  padding: 50px 0;
  margin-bottom: 40px;
}
.similar-products.-w-100 {
  width: 100%;
}
.similar-products.-w-100 .slider-similar-products {
  width: 100%;
}
.similar-products.-white-bg {
  background: rgb(255, 255, 255);
}
.similar-products .slider-similar-products {
  width: 75rem;
  margin: 0 auto;
}
@media screen and (max-width: 75rem) {
  .similar-products .slider-similar-products {
    width: 100%;
  }
}
.similar-products .slick-slide {
  background: rgb(255, 255, 255);
  margin: 0 10px;
  transition: all 0.3s 0.7s;
}
@media screen and (max-width: 43.6875em) {
  .similar-products .slick-slide {
    margin: 0 50px;
  }
}
.similar-products .slick-slide .similar-products-content {
  background: #000;
  padding: 15px 20px 5px;
}
.similar-products .slick-slide .similar-products-title {
  color: rgb(218, 50, 42);
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 28px;
  line-height: 28px;
}
.similar-products .slick-slide .similar-products-button {
  width: 100%;
}
.similar-products .slick-slide .similar-products-button a {
  margin: 0;
  background: #000;
  width: 100%;
  padding: 0 20px 14px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 22px;
  line-height: 22px;
  text-align: left;
}
.similar-products .slick-slide .similar-products-button a:hover {
  text-decoration: underline;
}

.multibox-big {
  width: 100%;
  background: rgb(244, 244, 244);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.multibox-big .media {
  width: 100%;
}
.multibox-big .media img {
  width: 100%;
  height: auto;
}
.multibox-big .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}
@media screen and (max-width: 43.6875em) {
  .multibox-big .content {
    padding: 25px;
  }
}
.multibox-big .content .headline {
  display: block;
  margin: 0 0 20px;
}
.multibox-big .content .headline * {
  margin: 0;
  padding: 0;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 28px;
  color: rgb(218, 50, 42);
  line-height: 28px;
}
.multibox-big .content .button {
  display: inline-table;
  padding: 10px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  background: #000;
}
.multibox-big .content .button:hover {
  color: rgb(218, 50, 42);
}
.multibox-big .content p {
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

.start-slider {
  height: calc(100vh - 80px);
}
@media screen and (max-width: 63.9375em) {
  .start-slider {
    height: calc(100vh - 60px);
  }
}
.start-slider .slick-prev,
.start-slider .slick-next {
  bottom: 30px;
}
.start-slider .slick-slide {
  position: relative;
  height: calc(100vh - 80px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 63.9375em) {
  .start-slider .slick-slide {
    height: calc(100vh - 60px);
  }
}
.start-slider .slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 800;
}
.start-slider .slider-content {
  height: 140%;
  position: relative;
  z-index: 900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 220px 20px 0;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 43.6875em) {
  .start-slider .slider-content {
    height: 100%;
  }
}
.start-slider .slider-content .headline {
  padding: 5px 18px;
  background: rgb(218, 50, 42);
  font-size: 74px;
  line-height: inherit;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
}
@media screen and (max-width: 63.9375em) {
  .start-slider .slider-content .headline {
    font-size: 48px;
  }
}
.start-slider .slider-content .content {
  padding: 10px 15px;
  font-size: 36px;
  line-height: inherit;
  background: rgba(245, 245, 245, 0.9);
  text-align: center;
  color: #000;
}
@media screen and (max-width: 63.9375em) {
  .start-slider .slider-content .content {
    font-size: 28px;
  }
}
@media screen and (max-width: 43.6875em) {
  .start-slider .slider-content .content {
    padding: 5px 10px;
    font-size: 18px;
  }
}

.slider-count-wrapper {
  width: 75rem;
  margin: 0 auto;
  padding: 15px 25px 0;
}
@media screen and (max-width: 75rem) {
  .slider-count-wrapper {
    width: 100%;
  }
}
.slider-count-wrapper .slider-count {
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: rgb(218, 50, 42);
}

.contact-content-wrapper {
  margin-bottom: 40px;
}
.contact-content-wrapper .contact-box {
  width: 100%;
  background: rgb(244, 244, 244);
  padding: 30px;
}
.contact-content-wrapper .contact-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.contact-content-wrapper .contact-button-wrapper .button {
  display: inline-table;
  margin: 0;
  padding: 10px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  background: #000;
}
.contact-content-wrapper .contact-button-wrapper .button:hover {
  color: rgb(218, 50, 42);
}

@media screen and (max-width: 43.6875em) {
  .contact-box {
    margin-bottom: 20px;
  }
}

.contact-box-single .contact-content {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-single .contact-content {
    flex-direction: column;
  }
}
.contact-box-single .headline-box {
  margin-bottom: 20px;
}
.contact-box-single .headline-box .contact-title {
  display: block;
  margin: 0 0 10px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 26px;
  text-transform: uppercase;
  color: rgb(218, 50, 42);
  line-height: 30px;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-single .headline-box .contact-title {
    font-size: 22px;
    line-height: 24px;
  }
}
.contact-box-single .headline-box .contact-subtitle {
  font-size: 18px;
  color: rgb(77, 78, 76);
  line-height: 20px;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-single .headline-box .contact-subtitle {
    font-size: 16px;
    line-height: 18px;
  }
}
.contact-box-single .contact-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.contact-box-single .contact-image img {
  width: 70px;
  height: auto;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-single .contact-image {
    margin-right: 20px;
    width: 40px;
    height: 40px;
  }
  .contact-box-single .contact-image img {
    width: 40px;
    height: auto;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-single .contact-image {
    margin-right: 30px;
    margin-bottom: 30px;
    width: 70px;
    height: 70px;
  }
  .contact-box-single .contact-image img {
    width: 70px;
    height: auto;
  }
}
.contact-box-single .contact-info {
  margin-bottom: 5px;
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  line-height: 22px;
  color: rgb(64, 64, 64) !important;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-single .contact-info {
    font-size: 16px;
    line-height: 20px;
  }
}
.contact-box-single .contact-info a {
  margin: 0;
  padding: 0;
  color: rgb(218, 50, 42) !important;
}
.contact-box-single .contact-info .label {
  background: 0 none;
  padding: 0;
  margin: 0;
  color: rgb(77, 78, 76);
  font-size: 18px;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-single .contact-info .label {
    font-size: 16px;
    line-height: 20px;
  }
}
.contact-box-single .contact-info.contact-icon:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: rgb(218, 50, 42);
  font-family: FontAwesome;
  font-size: 18px;
}
.contact-box-single .contact-info.contact-icon.phone:before {
  content: "\f095";
}
.contact-box-single .contact-info.contact-icon.fax:before {
  content: "\f1ac";
}
.contact-box-single .contact-info.contact-icon.mail:before {
  content: "\f003";
}
.contact-box-single .contact-info.contact-icon.time:before {
  content: "\f017";
}
.contact-box-single .contact-info.contact-icon.location {
  margin-bottom: 15px;
}
.contact-box-single .contact-info.contact-icon.location a {
  font-size: 14px;
  font-weight: 600;
}
.contact-box-single .contact-info.contact-icon.location:before {
  content: "\f124";
}
.contact-box-single .contact-info.contact-icon.web:before {
  content: "\f0c1";
}

.news-article {
  background: rgb(244, 244, 244);
  padding: 30px;
}
.news-article time {
  display: inline-block;
  font-family: "FrutigerNextCondens", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: rgb(64, 64, 64);
  margin-bottom: 10px;
}
.news-article .read-more,
.news-article .back {
  padding: 10px;
  background: rgb(218, 50, 42);
  font-size: 18px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  transition: all 0.3s;
}
.news-article .read-more:hover,
.news-article .back:hover {
  background: #d94100;
  transition: all 0.3s;
}
.news-article figure {
  margin-bottom: 30px;
}
.news-article figure img {
  width: 100%;
  height: auto;
}
.news-article.-new {
  padding: 15px 15px 30px;
  min-height: 100%;
}
.news-article.-new header {
  min-height: 60px;
}
.news-article.-new .imgWrapper {
  max-height: 300px;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
}
.news-article.-new .imgWrapper img {
  max-width: 100%;
  min-height: 300px;
}
.news-article.-new h1,
.news-article.-new h2,
.news-article.-new h3,
.news-article.-new h4,
.news-article.-new h5,
.news-article.-new h6 {
  font-size: 21px;
  font-weight: normal;
}
.news-article.-new p {
  margin-top: 0.5rem;
}

.news-list .news-article h2,
.news-list .news-article h3,
.news-list .news-article h4,
.news-list .news-article h5,
.news-list .news-article h6 {
  font-size: 26px;
  line-height: 30px;
}

.news-detail .news-article h2,
.news-detail .news-article h3,
.news-detail .news-article h4,
.news-detail .news-article h5,
.news-detail .news-article h6 {
  margin-bottom: 15px;
}
.news-detail .news-article p {
  margin-bottom: 30px;
}

label {
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 22px;
  color: rgb(218, 50, 42);
  text-transform: uppercase;
}
label[for*=checkbox] {
  font-family: "FrutigerNextCondens", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: inherit;
  line-height: 18px;
  position: relative;
  top: 2px;
  color: rgb(64, 64, 64);
}
label[for*=checkbox] a {
  text-decoration: underline;
  color: rgb(218, 50, 42);
}
label[for*=checkbox] a:hover {
  text-decoration: none;
}

input {
  margin-bottom: 40px;
}
input[type=text], input[type=password], input[type=search], input[type=email], input[type=number] {
  width: 100%;
  height: 48px;
  background: rgb(255, 255, 255);
  outline: none;
  box-shadow: none;
  border: 2px solid rgb(224, 224, 224);
  color: rgb(218, 50, 42);
  font-family: "FrutigerNextCondens", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  line-height: inherit;
  padding: 0 10px 1px;
}
input[type=text]:focus, input[type=password]:focus, input[type=search]:focus, input[type=email]:focus, input[type=number]:focus {
  outline: none;
  border: 2px solid rgb(224, 224, 224);
  border-bottom-color: rgb(218, 50, 42);
  box-shadow: none;
}

textarea {
  resize: none;
  height: 200px;
  background: rgb(255, 255, 255);
  outline: none;
  box-shadow: none;
  border: 2px solid rgb(224, 224, 224);
  color: rgb(64, 64, 64);
  font-family: "FrutigerNextCondens", sans-serif;
  font-size: 18px;
  line-height: inherit;
  padding: 10px;
}
textarea:focus {
  outline: none;
  border: 2px solid rgb(224, 224, 224);
  border-bottom-color: rgb(218, 50, 42);
  box-shadow: none;
}

button {
  margin: 0;
  padding: 0;
  border: 0 none;
  box-shadow: none;
  outline: none;
}
button:hover {
  text-decoration: none;
  cursor: pointer;
}
button[type=submit] {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 15px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 22px;
  line-height: 22px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  background: #000;
}
@media screen and (max-width: 63.9375em) {
  button[type=submit] {
    width: 100%;
  }
}
button[type=submit]:hover {
  color: rgb(218, 50, 42);
}
button[type=submit]:focus {
  outline: none;
  border: 0 none;
  box-shadow: none;
}

.select-wrapper {
  position: relative;
}
.select-wrapper:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-right: 1px solid rgb(218, 50, 42);
  border-top: 1px solid rgb(218, 50, 42);
  transform: rotate(135deg);
  position: absolute;
  right: 13px;
  top: 17px;
  transition: all 0.3s linear;
}
.select-wrapper:after:hover {
  cursor: pointer;
}
.select-wrapper select {
  height: 48px;
  background: rgb(255, 255, 255);
  border: 2px solid rgb(224, 224, 224);
  outline: none;
  box-shadow: none;
  color: rgb(64, 64, 64);
  font-family: "FrutigerNextCondens", sans-serif;
  font-size: 18px;
  line-height: inherit;
  padding: 0 10px;
}
.select-wrapper select:hover {
  cursor: pointer;
}
.select-wrapper select:focus {
  outline: none;
  border: 2px solid rgb(224, 224, 224);
  border-bottom-color: rgb(218, 50, 42);
  box-shadow: none;
}

.contact-map-wrapper {
  background: rgb(244, 244, 244);
  padding: 70px 0;
}
.contact-map-wrapper .map-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 639px) {
  .contact-map-wrapper .map-wrapper {
    display: none;
  }
}
.contact-map-wrapper .map-wrapper .map {
  position: relative;
}
.contact-map-wrapper .map-wrapper .map .mapImage {
  height: auto;
  width: 100%;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot {
  position: absolute;
  cursor: pointer;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker {
  position: relative;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker .map-marker-info-wrapper {
  display: flex;
  justify-content: center;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker .map-marker-info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: max-content;
  bottom: 25px;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker img {
  height: 20px;
  width: 20px;
  margin-top: 6px;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker span {
  text-transform: uppercase;
  background: black;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding: 4px 4px 2px 4px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  position: relative;
  width: 100%;
  text-align: center;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker span:last-of-type::after, .contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker span:only-of-type::after {
  display: none;
}
.contact-map-wrapper .map-wrapper .map .map-marker-wrapper .map-marker-dot .map-marker span::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 90%;
  left: 50%;
  transform: translate(-50%);
  bottom: -1px;
  z-index: 1;
  background: white;
}
.contact-map-wrapper .accordion {
  background: transparent;
  margin-bottom: 0;
  max-height: 765px;
  overflow: scroll;
}
.contact-map-wrapper .accordion::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 639px) {
  .contact-map-wrapper .accordion {
    max-height: unset;
  }
}
.contact-map-wrapper .accordion .accordion-item {
  background: white;
  border: 1px solid #E2E2E2;
  margin-bottom: 11px;
}
.contact-map-wrapper .accordion .accordion-item:last-of-type {
  margin-bottom: 0;
}
.contact-map-wrapper .accordion .accordion-item.is-active .accordion-title {
  align-items: flex-start;
}
.contact-map-wrapper .accordion .accordion-item.is-active .accordion-title span {
  color: rgb(218, 50, 42);
  transition: all 0.35s;
}
.contact-map-wrapper .accordion .accordion-item.is-active .accordion-title .button {
  display: none;
}
.contact-map-wrapper .accordion .accordion-item.is-active .accordion-title .close {
  display: block;
}
.contact-map-wrapper .accordion .accordion-item .accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  padding: 15px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
.contact-map-wrapper .accordion .accordion-item .accordion-title::before {
  display: none;
}
.contact-map-wrapper .accordion .accordion-item .accordion-title:hover, .contact-map-wrapper .accordion .accordion-item .accordion-title:focus {
  background: white;
}
.contact-map-wrapper .accordion .accordion-item .accordion-title .title {
  margin-right: 30px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 500;
  color: black;
}
@media screen and (max-width: 1024px) {
  .contact-map-wrapper .accordion .accordion-item .accordion-title .title {
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: 639px) {
  .contact-map-wrapper .accordion .accordion-item .accordion-title .title {
    font-size: 16px;
    line-height: 20px;
  }
}
.contact-map-wrapper .accordion .accordion-item .accordion-title .button {
  font-size: 12px;
  line-height: 12px;
  color: black;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 7px 10px 4px 10px;
  background: white;
  border: 2px solid black;
  margin: 0;
  text-transform: uppercase;
}
.contact-map-wrapper .accordion .accordion-item .accordion-title .close {
  display: none;
  position: relative;
  height: 18px;
  width: 18px;
}
.contact-map-wrapper .accordion .accordion-item .accordion-title .close::after, .contact-map-wrapper .accordion .accordion-item .accordion-title .close::before {
  content: "";
  position: absolute;
  height: 20px;
  width: 3px;
  background: rgb(218, 50, 42);
  transform: rotate(45deg);
}
.contact-map-wrapper .accordion .accordion-item .accordion-title .close::before {
  transform: rotate(315deg);
}
.contact-map-wrapper .accordion .accordion-item .accordion-content {
  border: 0;
  padding-top: 0;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
@media screen and (max-width: 950px) {
  .contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box {
    flex-direction: column;
    align-items: flex-start;
  }
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box a > img {
  max-width: 120px;
  max-height: 80px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-right: 55px;
}
@media screen and (max-width: 950px) {
  .contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box a > img {
    max-width: 140px;
    margin: 5px 0 20px 0;
  }
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info {
  display: flex;
  flex-direction: column;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a {
  color: black;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a:hover {
  color: rgb(218, 50, 42);
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin: 0 10px 0 0;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a.button {
  color: black;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  background: transparent;
  position: relative;
  padding: 0;
  margin: 18px 0 0 0;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a.button::after {
  content: "";
  position: absolute;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  height: 7px;
  width: 7px;
  transform: rotate(315deg);
  top: 1px;
  right: 39px;
  transition: all 0.35s;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a.button:hover {
  color: rgb(218, 50, 42);
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .contact-box .contact-info a.button:hover::after {
  right: 36px;
  border-color: rgb(218, 50, 42);
  transition: all 0.35s;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .location-wrapper .location {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .location-wrapper .location:first-of-type {
  margin-top: 34px;
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .location-wrapper .location .title {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
  color: rgb(218, 50, 42);
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 639px) {
  .contact-map-wrapper .accordion .accordion-item .accordion-content .location-wrapper .location .title {
    font-size: 14px;
  }
}
.contact-map-wrapper .accordion .accordion-item .accordion-content .location-wrapper .location .address {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: black;
}

/* .contact-map-wrapper {
  position: relative;
  display: block;

  @include breakpoint(small down) {
    display: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0.8);
    }

    70% {
      -webkit-box-shadow: 0 0 0 20px rgba(218, 50, 42, 0);
    }

    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0);
    }
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0.8);
      box-shadow: 0 0 0 0 rgba(218, 50, 42, 0.8);
    }

    70% {
      -moz-box-shadow: 0 0 0 20px rgba(218, 50, 42, 0);
      box-shadow: 0 0 0 10px rgba(218, 50, 42, 0);
    }

    100% {
      -moz-box-shadow: 0 0 0 0 rgba(218, 50, 42, 0);
      box-shadow: 0 0 0 0 rgba(218, 50, 42, 0);
    }
  }

  .map-marker-wrapper {
    .map-marker {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: rgba(218, 50, 42, 0.8);
      animation: none;
      transition: background .35s;

      &:hover,
      &.is-active {
        background: rgba(218, 50, 42, 1);
        cursor: pointer;
        box-shadow: 0 0 0 rgba(218, 50, 42, 0.8);
        animation: pulse 2s infinite;
        transition: background .35s;
      }

      &.secondary {
        background-color: $black;
      }

      &-01 {
        top: 27%;
        left: 30%;
      }

      &-02 {
        top: 47%;
        left: 50%;
      }

      &-03 {
        top: 37%;
        left: 70%;
      }
    }
  }
}

.accordion {
  border: 0 none !important;

  * {
    border: 0 none !important;
  }

  .accordion-title {
    font: {
      family: $primary-font-family;
      size: 20px;
    }

    transition: background-color .35s;
    padding-right: 50px;

    &:before {
      top: 28px;
    }

    &:focus {
      background: 0 none;
      color: $primary-color;
    }

    &:hover {
      background: $ultra-light-gray;
      color: $primary-color;
      transition: background-color .35s;
    }
  }

  .accordion-item {
    &.is-active {
      .accordion-title {
        background: $black;
        color: $primary-color;
        transition: background-color .35s;
      }
    }
  }

  .accordion-content {
    padding: 20px 0 0;

    .contact-box {
      margin-bottom: 10px;

      &:last-of-type {
        margin: 0;
      }
    }
  }
} */
.neos #neos-inspector .neos-inspector-form .neos-inspector-field label {
  color: #fff !important;
}

.neos [class^=“icon-“],
.neos [class*=”icon-“] {
  color: white !important;
}

.neos #neos-context-bar #neos-aloha-topmenu .chosen-container .chosen-drop .chosen-results li {
  color: white;
}

.neos label {
  text-transform: none;
}

.neos #neos-inspector .neos-inspector-form input[type=text],
.neos #neos-inspector .neos-inspector-form input[type=password],
.neos #neos-inspector .neos-inspector-form input[type=datetime],
.neos #neos-inspector .neos-inspector-form input[type=datetime-local],
.neos #neos-inspector .neos-inspector-form input[type=date],
.neos #neos-inspector .neos-inspector-form input[type=month],
.neos #neos-inspector .neos-inspector-form input[type=time],
.neos #neos-inspector .neos-inspector-form input[type=week],
.neos #neos-inspector .neos-inspector-form input[type=number],
.neos #neos-inspector .neos-inspector-form input[type=range],
.neos #neos-inspector .neos-inspector-form input[type=date],
.neos #neos-inspector .neos-inspector-form input[type=email],
.neos #neos-inspector .neos-inspector-form input[type=url],
.neos #neos-inspector .neos-inspector-form input[type=search],
.neos #neos-inspector .neos-inspector-form input[type=tel],
.neos #neos-inspector .neos-inspector-form input[type=color],
.neos #neos-inspector .neos-inspector-form input[type=number],
.neos #neos-inspector .neos-inspector-form textarea,
.neos #neos-inspector .neos-inspector-form .neos-uneditable-input {
  text-transform: none;
}

.bg-text-top {
  background-color: black;
}
.bg-text-top.slick-slide {
  background-size: contain;
  background-position: center bottom;
}
.bg-text-top .slider-content .headline {
  background-color: transparent;
  position: absolute;
  top: 20px;
  font-size: 106px;
  line-height: 1;
  width: 700px;
  text-align: center;
  transition: all 0.35s;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 700px) {
  .bg-text-top .slider-content .headline {
    font-size: 74.5px;
  }
  .bg-text-top .slider-content .headline span {
    font-size: 81px;
  }
}
.bg-text-top .slider-content .headline span {
  font-size: 103.5px;
  color: red;
  transition: all 0.35s;
}
@media screen and (max-width: 700px) {
  .bg-text-top .slider-content .headline span {
    font-size: 73px;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #da322a;
  font-weight: 500;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}

.h1,
.h2 {
  font-size: 2.5rem;
}
@media screen and (max-width: 639px) {
  .h1,
  .h2 {
    font-size: 2.25rem;
  }
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5,
.h6 {
  font-size: 1.25rem;
}

.headline {
  height: auto;
  left: calc(50% - 22rem + 2px);
}
.headline.-black > * {
  color: black;
}
.headline.-text-center > * {
  text-align: center;
}

.cellArticle {
  margin-bottom: 30px !important;
}

.stickyButton-wrapper {
  position: fixed;
  top: 252px;
  right: 0;
  z-index: 999;
}
.stickyButton-wrapper a .stickyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  background: black;
  transition: all 0.3s;
  border-top: 0.1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  border-left: 1px solid rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 700px) {
  .stickyButton-wrapper a .stickyButton {
    height: 60px;
    width: 60px;
  }
}
@media screen and (max-width: 43.6875em) {
  .stickyButton-wrapper a .stickyButton {
    height: 40px;
    width: 40px;
  }
}
.stickyButton-wrapper a .stickyButton:hover {
  background: rgb(29, 29, 29);
  transition: all 0.3s;
}
.stickyButton-wrapper a .stickyButton img {
  height: 40px;
  width: 40px;
}
@media screen and (max-width: 43.6875em) {
  .stickyButton-wrapper a .stickyButton img {
    height: 20px;
    width: 20px;
  }
}
.stickyButton-wrapper a .stickyButton.-small img {
  width: 26px;
  height: 26px;
}

.headerImage {
  width: 100%;
  height: 40vw;
  max-height: 750px;
  min-height: 300px;
}
.headerImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.anchorTags {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .anchorTags {
    flex-direction: column;
    padding: 15px 0;
  }
}
.anchorTags a {
  font-weight: 700;
  font-size: 22px;
}
@media screen and (max-width: 1024px) {
  .anchorTags a {
    text-align: center;
    margin: 5px 0px;
  }
}

.content-wrapper .counter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0 21px 0;
}
@media screen and (max-width: 1024px) {
  .content-wrapper .counter-wrapper {
    flex-direction: column;
    padding: 30px 0;
  }
}
.content-wrapper .counter-wrapper .counterItem {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .content-wrapper .counter-wrapper .counterItem {
    margin-bottom: 20px;
  }
  .content-wrapper .counter-wrapper .counterItem:last-of-type {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 700px) {
  .content-wrapper .counter-wrapper .counterItem {
    margin-bottom: 10px;
  }
  .content-wrapper .counter-wrapper .counterItem:last-of-type {
    margin-bottom: 0;
  }
}
.content-wrapper .counter-wrapper .counterItem span {
  color: rgb(218, 50, 42);
  font-family: "Impact";
  text-align: center;
  font-size: 22px;
}
@media screen and (max-width: 1024px) {
  .content-wrapper .counter-wrapper .counterItem span {
    font-size: 20px;
  }
}
@media screen and (max-width: 700px) {
  .content-wrapper .counter-wrapper .counterItem span {
    font-size: 16px;
  }
}
.content-wrapper .counter-wrapper .counterItem .counterNumber {
  font-size: 60px;
  width: 160px;
  color: white;
  font-weight: 900;
}
@media screen and (max-width: 1024px) {
  .content-wrapper .counter-wrapper .counterItem .counterNumber {
    font-size: 50px;
  }
}
@media screen and (max-width: 700px) {
  .content-wrapper .counter-wrapper .counterItem .counterNumber {
    font-size: 28px;
  }
}
.content-wrapper.light-grey .counter-wrapper .counterItem span {
  color: black;
}
.content-wrapper.light-grey .counter-wrapper .counterItem .counterNumber {
  color: rgb(218, 50, 42);
}

@media screen and (max-width: 700px) {
  .order-wrapper {
    display: none;
  }
}
.order-wrapper.order-slider-wrapper {
  display: none;
}
@media screen and (max-width: 700px) {
  .order-wrapper.order-slider-wrapper {
    display: block;
  }
  .order-wrapper.order-slider-wrapper .order-slider .order-item .order-header {
    display: flex;
    justify-content: center;
  }
  .order-wrapper.order-slider-wrapper .order-slider .order-item .order-header .number {
    width: 31px;
    height: 31px;
    font-size: 14px;
  }
  .order-wrapper.order-slider-wrapper .order-slider .order-item .order-header img {
    display: none;
  }
  .order-wrapper.order-slider-wrapper .order-slider .order-item .order-text {
    text-align: center;
  }
  .order-wrapper.order-slider-wrapper .order-slider .order-item .order-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-wrapper.order-slider-wrapper .order-slider .slick-arrow {
    left: -30px;
    top: 50%;
  }
  .order-wrapper.order-slider-wrapper .order-slider .slick-arrow::before {
    background-image: none;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    width: 19px;
    height: 19px;
    transform: rotate(135deg);
    border-radius: 2px;
    left: 0;
    position: absolute;
  }
  .order-wrapper.order-slider-wrapper .order-slider .slick-next {
    left: unset;
    right: -30px;
  }
  .order-wrapper.order-slider-wrapper .order-slider .slick-next::before {
    transform: rotate(-45deg);
    left: unset;
    right: 0;
  }
}
.order-wrapper .order-item {
  position: relative;
}
.order-wrapper .order-item .order-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.order-wrapper .order-item .order-header .number {
  color: black;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  font-family: "Impact";
  font-weight: 500;
  font-size: 12px;
}
.order-wrapper .order-item .order-header img {
  height: 21px;
}
.order-wrapper .order-item .order-image {
  margin-bottom: 35px;
}
.order-wrapper .order-item .order-text span {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}
@media screen and (max-width: 700px) {
  .order-wrapper .order-item .order-text span {
    margin-bottom: 5px;
  }
}
.order-wrapper .order-item .order-text p {
  font-size: 16px;
}

.additional-button-margin a.button {
  margin: 0 10px;
}

.single-product-slider-wrapper {
  padding: 50px 0;
  margin-bottom: 40px;
}
@media screen and (max-width: 43.6875em) {
  .single-product-slider-wrapper {
    margin-bottom: 0;
  }
}
.single-product-slider-wrapper .single-product-slider {
  width: 100%;
  margin: 0 auto;
}
.single-product-slider-wrapper .slider-count-wrapper {
  width: 100%;
}
.single-product-slider-wrapper .slick-slide {
  background: rgb(255, 255, 255);
  margin: 0 10px;
  transition: all 0.3s 0.7s;
}
.single-product-slider-wrapper .slick-slide .single-product-image {
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 43.6875em) {
  .single-product-slider-wrapper .slick-slide .single-product-image {
    height: 200px;
  }
}
.single-product-slider-wrapper .slick-slide .single-product-image img {
  height: 100%;
  width: auto;
  object-fit: contain;
}
.single-product-slider-wrapper .slick-slide .single-product-content {
  background: #000;
  padding: 15px 20px 5px;
  display: inline-flex;
}
.single-product-slider-wrapper .slick-slide .single-product-title {
  color: rgb(218, 50, 42);
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 28px;
  line-height: 28px;
}
.single-product-slider-wrapper .slick-slide .single-product-button {
  width: 100%;
}
.single-product-slider-wrapper .slick-slide .single-product-button a {
  margin: 0;
  background: #000;
  width: 100%;
  padding: 0 20px 14px;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  font-size: 22px;
  line-height: 22px;
  text-align: left;
}
.single-product-slider-wrapper .slick-slide .single-product-button a:hover {
  text-decoration: underline;
}

.category-item {
  padding: 0 4px;
  display: block;
}
.category-item .imgWrapper {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.category-item .imgWrapper img {
  max-width: 55px;
  display: block;
}
.category-item span {
  font-size: 27px;
  line-height: 1;
  color: rgb(218, 50, 42);
  font-family: Impact, Helvetica, Arial, sans-serif;
  display: block;
  margin-top: 40px;
  text-align: center;
  hyphens: auto;
}
@media screen and (max-width: 768px) {
  .category-item span {
    font-size: 24px;
    line-height: 27px;
  }
}

.retailer-info > .imgWrapper {
  margin-bottom: 16px;
}
.retailer-info > div:last-of-type {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  border-top: 1px solid #000;
  padding-top: 24px;
}
@media screen and (max-width: 75rem) {
  .retailer-info > div:last-of-type {
    margin-top: 48px;
  }
}
@media screen and (max-width: 639px) {
  .retailer-info > div:last-of-type {
    flex-direction: column;
  }
}
.retailer-info > div:last-of-type .imgWrapper {
  max-width: 180px;
}
@media screen and (max-width: 639px) {
  .retailer-info > div:last-of-type .imgWrapper {
    margin-top: 32px;
  }
}
.retailer-info > div:last-of-type > div {
  display: flex;
}
@media screen and (max-width: 1024px) {
  .retailer-info > div:last-of-type > div {
    flex-direction: column;
  }
}
.retailer-info .contact-person {
  display: flex;
  flex-direction: column;
}
.retailer-info .contact-person span {
  font-size: 15px;
  line-height: 22px;
}
.retailer-info .contact-person span:first-of-type {
  font-family: Impact, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 1px;
}
.retailer-info .contact-person a {
  color: #000;
  font-size: 15px;
  line-height: 22px;
}
.retailer-info .contact-person + .contact-person {
  margin-left: 80px;
}
@media screen and (max-width: 1024px) {
  .retailer-info .contact-person + .contact-person {
    margin-left: unset;
    margin-top: 32px;
  }
}

.headline-box.-big h1,
.headline-box.-big h2,
.headline-box.-big h3,
.headline-box.-big h4,
.headline-box.-big h5,
.headline-box.-big h6 {
  font-size: 72px;
  line-height: 80px;
  font-weight: 400;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  color: #000;
  hyphens: auto;
}
@media screen and (max-width: 75rem) {
  .headline-box.-big h1,
  .headline-box.-big h2,
  .headline-box.-big h3,
  .headline-box.-big h4,
  .headline-box.-big h5,
  .headline-box.-big h6 {
    font-size: 62px;
    line-height: 70px;
  }
}
@media screen and (max-width: 639px) {
  .headline-box.-big h1,
  .headline-box.-big h2,
  .headline-box.-big h3,
  .headline-box.-big h4,
  .headline-box.-big h5,
  .headline-box.-big h6 {
    font-size: 34px;
    line-height: 44px;
  }
}

.icon-item .imgWrapper {
  height: 60px;
  margin-bottom: 12px;
}
.icon-item .imgWrapper img {
  margin: 0 auto;
  display: block;
  height: 100%;
}
.icon-item span {
  font-size: 15px;
  font-weight: 400;
  display: block;
  line-height: 1;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  color: #000;
  text-align: center;
}

.map-locations > span {
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  margin-bottom: 32px;
  display: block;
}
.map-locations > div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.map-locations > div span {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 500;
  width: 100%;
}
.map-locations > div span:first-of-type {
  color: rgb(218, 50, 42);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
.map-locations > div span + span {
  margin-top: 4px;
}
.map-locations > div + div {
  margin-top: 20px;
}

.prod-verg-wrapper .content-wrapper-box > div .headline-box * {
  font-size: 53px;
  line-height: 1;
  margin-bottom: 24px;
}
@media screen and (max-width: 768px) {
  .prod-verg-wrapper .content-wrapper-box > div .headline-box * {
    font-size: 34px;
    line-height: 44px;
  }
}

.calculator-wrapper {
  position: relative;
}
.calculator-wrapper .content-wrapper-box > div .headline-box * {
  font-size: 53px;
  line-height: 60px;
  margin-bottom: 24px;
}
@media screen and (max-width: 768px) {
  .calculator-wrapper .content-wrapper-box > div .headline-box * {
    font-size: 34px;
    line-height: 44px;
  }
}
.calculator-wrapper .content-wrapper-box > div .button {
  margin-top: 24px;
}

.calculator {
  background: #F4F4F4;
  padding: 16px;
}
.calculator table {
  border-collapse: collapse;
}
@media screen and (max-width: 768px) {
  .calculator table {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .calculator table thead {
    display: none;
  }
}
.calculator table thead tr td {
  border-bottom: 1px solid rgb(226, 226, 226);
  padding: 0 2rem 20px;
  text-align: left;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
}
.calculator table thead tr td:first-of-type {
  border-right: 1px solid rgb(226, 226, 226);
  padding: unset;
}
.calculator table thead tr td:last-of-type {
  font-weight: 600;
}
.calculator table tbody {
  width: 100%;
}
.calculator table tbody tr {
  width: 100%;
  padding-bottom: 24px;
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr {
    padding-bottom: 16px;
  }
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgb(226, 226, 226);
  }
  .calculator table tbody tr:last-of-type {
    border-bottom: 0 none;
  }
}
.calculator table tbody tr + tr {
  padding: 24px 0;
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr + tr {
    padding: 16px 0;
  }
}
.calculator table tbody tr td {
  padding: 24px 0;
}
.calculator table tbody tr td + td {
  padding: 24px 32px;
  width: 25%;
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr td + td {
    width: 50%;
    padding: 0 24px 0 0;
  }
}
.calculator table tbody tr td:first-of-type {
  border-right: 1px solid rgb(226, 226, 226);
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr td:first-of-type {
    display: flex;
    width: 100%;
    padding: 0 0 8px 0;
    border-right: 0 none;
  }
}
.calculator table tbody tr td:first-of-type::after {
  content: "";
  height: 100%;
  width: 1px;
  background: #000;
  right: 0;
  top: 0;
  bottom: 0;
}
.calculator table tbody tr td:first-of-type span {
  border: 0 none;
  padding-bottom: 0;
  color: rgb(218, 50, 42);
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  margin: unset;
  text-align: left;
}
.calculator table tbody tr td:first-of-type span + span {
  padding-top: 0;
  color: #000;
  font-weight: 300;
  font-size: 10px;
}
.calculator table tbody tr td:not(.calculator table tbody tr td:first-of-type) span {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1;
  justify-content: center;
}
.calculator table tbody tr td:not(.calculator table tbody tr td:first-of-type) span.mobile-col-title {
  margin-bottom: 8px;
}
.calculator table tbody tr td:last-of-type span {
  font-weight: 500;
  border: 0 none;
}
.calculator table tbody tr td:last-of-type span + span {
  font-weight: 600;
}
.calculator table tbody tr td input {
  background: #F4F4F4;
  border: 1px solid rgb(64, 64, 64);
  padding: 8px;
  border-radius: 2px;
  box-sizing: border-box;
}
.calculator table tbody tr td .input-group {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: start;
}
.calculator table tbody tr td .input-group.-icon input {
  border-right: 0 none;
  padding-right: 0;
  width: 50px;
  border-radius: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.calculator table tbody tr td .input-group.-icon input:focus {
  border-right: 0 none;
}
.calculator table tbody tr td .input-group.-icon .input-group-label {
  background: transparent;
  padding: 8px 4px;
  border-radius: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.calculator table tbody tr td .input-group input {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 30px;
  font-size: 12px;
  line-height: 1;
  color: #000;
  font-weight: 300;
  border-radius: 1px;
}
.calculator table tbody tr td .input-group input:focus {
  background: transparent;
  border: 1px solid #000;
}
.calculator table tbody tr td .input-group input::-webkit-outer-spin-button,
.calculator table tbody tr td .input-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.calculator table tbody tr td .input-group input[type=number] {
  -moz-appearance: textfield;
}
.calculator table tbody tr td .mobile-col-title {
  display: none;
  border: 0 none;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin: unset;
  width: 50px;
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr td .mobile-col-title {
    display: block;
    padding-left: 0;
    width: 50px;
  }
}
@media screen and (max-width: 768px) {
  .calculator table tbody tr td {
    padding-top: 0;
    width: max-content;
  }
}
@media screen and (max-width: 600px) {
  .calculator table tbody tr td {
    padding-top: 0;
    width: 33.3333333333%;
  }
  .calculator table tbody tr td .mobile-col-title {
    width: 100%;
  }
}

.prod-verg {
  display: flex;
  border-collapse: collapse;
}
.prod-verg thead tr td,
.prod-verg tbody tr td {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.prod-verg thead tr td {
  font-size: 21px;
  line-height: 1;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .prod-verg thead tr td {
    font-size: 14px;
    line-height: 18px;
  }
}
.prod-verg thead tr:first-of-type td {
  height: 120px;
}
.prod-verg tbody {
  overflow: scroll;
  display: flex;
  width: 100%;
}
.prod-verg tbody::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 4px;
}
.prod-verg tbody::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.prod-verg tbody tr {
  min-width: 150px;
  width: 100%;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .prod-verg tbody tr {
    min-width: 125px;
  }
}
.prod-verg tbody tr td {
  width: 100%;
}
.prod-verg tbody tr td:first-of-type {
  height: 120px;
}
.prod-verg tbody tr td:first-of-type .imgWrapper {
  display: block;
  margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
  .prod-verg tbody tr td:first-of-type {
    flex-direction: column-reverse;
  }
}
.prod-verg tbody tr td {
  align-items: center;
}
.prod-verg tbody tr td span {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .prod-verg tbody tr td span {
    font-size: 14px;
    line-height: 18px;
  }
}

.primary-bg {
  background: rgb(218, 50, 42);
}
.primary-bg h1,
.primary-bg h2,
.primary-bg h3,
.primary-bg h4,
.primary-bg h5,
.primary-bg h6,
.primary-bg span,
.primary-bg a {
  color: #000;
}

.info-banner {
  color: #000;
  position: relative;
  overflow: hidden;
}
.info-banner::after {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  left: -40px;
  bottom: -103px;
  background-image: url("/assets/img/forsec-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.info-banner .content-wrapper-box > div .cell {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
}
.info-banner .content-wrapper-box > div .cell > span {
  margin-top: 8px;
}
.info-banner .content-wrapper-box > div .cell span,
.info-banner .content-wrapper-box > div .cell a {
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  font-family: "Impact", Helvetica, Arial, sans-serif;
  color: #000;
}

.retailer-map .content-wrapper-box > div {
  display: flex;
  justify-content: center;
}
.retailer-map .content-wrapper-box > div .cell:last-of-type {
  display: flex;
}
.retailer-map .content-wrapper-box > div .cell:last-of-type > div {
  margin: auto 0;
}
.retailer-map .content-wrapper-box > div .map-locations a {
  color: #000;
}
.retailer-map .content-wrapper-box > div .map-locations a:hover {
  text-decoration: underline;
}

.table-caption {
  margin-top: 8px;
  text-align: end;
  height: max-content;
}
.table-caption span {
  font-size: 10px;
  line-height: 13px;
  display: block;
  color: #000;
}

@media screen and (max-width: 43.6875em) {
  .cellArticle > .similar-products .similar-products-item {
    margin: 0 10px;
  }
}

.modal {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 64px;
  width: 100%;
  max-width: 805px;
  background-color: #F4F4F4;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1005;
}
.modal.active {
  display: flex;
}
.modal .close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 32px;
  top: 32px;
  border: 2px solid rgb(218, 50, 42);
  border-radius: 50%;
}
.modal .close:hover {
  cursor: pointer;
}
.modal .close::after, .modal .close::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: rgb(218, 50, 42);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal .close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal .close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal .headline-box {
  display: flex;
  justify-content: center;
}
.modal .headline-box > * {
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-family: "Impact", Helvetica, Arial, sans-serif;
}
.modal .headline-box + p {
  margin-top: 24px;
}
.modal p {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  margin: 0;
}
.modal p + a {
  margin-top: 40px;
}
.modal a {
  width: max-content;
}

.layer {
  position: relative;
  overflow: hidden;
}
.layer::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.-elementCenter {
  display: flex;
  justify-content: center;
}

.date-header {
  display: flex;
  justify-content: center;
}
.date-header > div {
  display: flex;
}
.date-header > div + div {
  margin-left: 24px;
}
.date-header > div span {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}
.date-header > div span:last-of-type {
  font-weight: 400;
  margin-left: 4px;
  display: block;
}

.img-caption {
  margin-top: 12px;
}
.img-caption > * {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-b-64 {
  padding-bottom: 64px;
}

.p-t-64 {
  padding-top: 64px;
}

.contact-box-item {
  width: 372px;
  padding: 16px;
  background: white;
  border: 1px solid #E2E2E2;
}
.contact-box-item ul {
  margin: 24px 0;
  padding: 0;
}
.contact-box-item ul li {
  list-style: none;
}
.contact-box-item > span {
  font-family: "Impact";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.contact-box-item a {
  color: black;
}
.contact-box-item a:hover {
  color: rgb(218, 50, 42);
}
.contact-box-item a img {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  margin: 0 10px 0 0;
}
.contact-box-item button {
  padding: 4px 8px;
  background: #FFFFFF;
  border: 2px solid #000000;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Impact";
}
.contact-box-item button:hover {
  border-color: rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}

.bold {
  font-family: "Impact";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}

.catalog-grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}
@media screen and (max-width: 1024px) {
  .catalog-grid {
    grid-gap: 16px;
  }
}
.catalog-grid .imgWrapper {
  display: block;
  width: 100%;
}
.catalog-grid .imgWrapper img {
  display: block;
  width: 100%;
}
.catalog-grid .button-border {
  margin-top: 8px;
}

.button-border {
  padding: 4px 8px;
  background: #FFFFFF;
  border: 2px solid #000000;
  color: black;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Impact";
  display: block;
  width: 100%;
  text-align: center;
}
.button-border:hover {
  border-color: rgb(218, 50, 42);
  color: rgb(218, 50, 42);
}

.news-item {
  border: 1px solid #E2E2E2;
  display: block;
}
.news-item .imgWrapper {
  width: 100%;
  display: block;
  border-bottom: 3px solid rgb(218, 50, 42);
  max-height: 260px;
  overflow: hidden;
  aspect-ratio: 372/320;
}
.news-item .imgWrapper img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all 0.35s linear;
}
.news-item .date-header {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.news-item .date-header div {
  display: flex;
}
.news-item .date-header div span {
  font-family: "FrutigerNext";
  font-weight: 300;
}
.news-item .date-header div span:last-of-type {
  font-weight: 500;
}
.news-item .date-header div:first-of-type {
  margin-right: 24px;
}
.news-item .date-header div + div {
  margin-left: 0;
}
.news-item .news-item-content {
  padding: 32px 16px 16px;
  background: white;
  box-sizing: border-box;
}
.news-item .news-item-content p {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.news-item:hover .imgWrapper img {
  transform: scale(1.1);
}

.imgWrapper.-border-grey img {
  border: 1px solid #E2E2E2;
}

.virtual-try-on {
  margin-bottom: 70px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .content-wrapper-box {
    padding: 0 16px;
  }
}
.virtual-try-on .virtual-try-on-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 999;
  padding: 10%;
}
.virtual-try-on .virtual-try-on-overlay.-active {
  display: flex;
}
.virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content {
  position: relative;
  background-color: white;
  max-width: 768px;
  width: 100%;
  padding: 80px;
  text-align: center;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content {
    padding: 40px;
  }
}
.virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content .headline-box {
  margin-bottom: 24px;
}
.virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content .headline-box > * {
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  text-transform: uppercase;
  color: black;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content .headline-box > * {
    font-size: 28px;
    line-height: 28px;
  }
}
.virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content p {
  margin-bottom: 40px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content p {
    margin-bottom: 20px;
  }
}
.virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content .button {
  font-weight: 600;
  margin: 0;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content .button {
    font-size: 16px;
  }
}
.virtual-try-on .virtual-try-on-overlay .virtual-try-on-overlay-content .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url("/assets/img/icon-close.svg");
  background-size: contain;
}
.virtual-try-on .virtual-try-on-glasses {
  position: relative;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider {
  display: flex;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider {
    flex-direction: column;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper {
  position: relative;
  border: 1px solid black;
  aspect-ratio: 600/560;
  width: calc(100% - 96px);
  margin-right: 24px;
  max-height: 560px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper {
    width: 100%;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-switch {
  position: absolute;
  top: 16px;
  right: 50%;
  transform: translateX(50%);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  width: max-content;
  color: white;
  padding: 16px 20px;
  border-radius: 40px;
  background-color: black;
  cursor: pointer;
  z-index: 10;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-switch {
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    right: 50%;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-switch.-light {
  background-color: #F4F4F4;
  color: black;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-camera-button {
  position: absolute;
  bottom: 24px;
  left: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  z-index: 1;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  border: 4px solid white;
  background-color: transparent;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.8));
  cursor: pointer;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-camera-button {
    left: 50%;
    bottom: 16px;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-camera-button.-active {
  display: flex;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-camera-button::after {
  content: "";
  width: 38px;
  height: 38px;
  position: absolute;
  border-radius: 50%;
  background-color: white;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list {
  position: absolute;
  bottom: -49px;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item {
  display: none;
  align-items: center;
  margin-right: 48px;
  cursor: pointer;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item {
    margin-right: 24px;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item.-active {
  display: flex;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item:hover span {
  text-decoration: underline;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item img {
  width: 36px;
  height: 36px;
  background-color: black;
  padding: 8px;
  border-radius: 50%;
  margin-right: 8px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item img {
    width: 32px;
    height: 32px;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .virtual-try-on-function-list .virtual-try-on-item span {
    font-size: 14px;
    line-height: 18px;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .slider-content {
  height: 100%;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .slider-content .slick-list {
  height: 100%;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .slider-content .slick-list .slick-track {
  height: 100%;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .slider-content .slick-list .slick-track .slider-item .imgWrapper {
  height: 100%;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-content-wrapper .slider-content .slick-list .slick-track .slider-item .imgWrapper img {
  height: 100%;
  object-fit: contain;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb {
  width: 96px;
  max-height: 560px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb {
    width: 100%;
    margin-top: 76px;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slick-list {
  margin: 64px 0;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slick-list {
    margin: 0;
    overflow: visible;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item {
  box-shadow: inset 0px 0px 0px 1px #000000;
  border-radius: 4px;
  margin: 0 0 16px 0;
  height: 96px;
  width: 96px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item {
    height: unset;
    width: unset;
    margin: 0 8px 0 0;
    aspect-ratio: 1/1;
  }
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item.slick-current {
  box-shadow: inset 0px 0px 0px 2px rgb(218, 50, 42);
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item.slick-active:last-of-type {
  margin-bottom: 0;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item:last-of-type {
  margin-bottom: 0;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item .imgWrapper {
  aspect-ratio: 96/96;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slider-thumb .slider-thumb-item .imgWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 2px;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slick-prev,
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slick-next {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: black;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slick-prev::before,
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slick-next::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  opacity: 1;
  top: 18px;
  background-image: unset;
  border-top: 3px solid white;
  border-right: 3px solid white;
  transform: rotate(-45deg);
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slick-next {
  top: unset;
  bottom: 0;
}
.virtual-try-on .virtual-try-on-glasses .virtual-try-on-slider .slick-next::before {
  top: 13px;
  transform: rotate(135deg);
}
@media screen and (max-width: 74.9375em) {
  .virtual-try-on .virtual-try-on-description {
    margin-top: 60px;
  }
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-description {
    margin-top: 0px;
  }
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item {
  display: none;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item.-active {
  display: block;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item .category {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item .headline-box {
  margin-bottom: 24px;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item .headline-box * {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  color: black;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul {
  margin: 0 0 40px 0;
  padding: 0 0 0 21px;
  list-style: none;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul {
    padding: 0 0 0 18px;
    margin: 0 0 24px 0;
  }
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul li {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul li {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul li:last-of-type {
  margin-bottom: 0;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul li::after {
  content: "#";
  position: absolute;
  top: -1px;
  left: -22px;
  display: inline;
  font-size: 23px;
  font-weight: 500;
  color: rgb(218, 50, 42);
}
@media screen and (max-width: 43.6875em) {
  .virtual-try-on .virtual-try-on-description .virtual-try-on-description-item ul li::after {
    top: 1px;
    font-size: 18px;
    left: -17px;
  }
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item a {
  position: relative;
  color: black;
  padding: 0 18px 0 0;
  background-color: unset;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item a:hover::before, .virtual-try-on .virtual-try-on-description .virtual-try-on-description-item a:hover::after {
  right: -3px;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item a::before, .virtual-try-on .virtual-try-on-description .virtual-try-on-description-item a::after {
  content: "";
  position: absolute;
  top: 8px;
  right: 0;
  width: 9px;
  height: 2px;
  background-color: black;
  transform: rotate(45deg);
  transition: all 0.35s ease-in-out;
}
.virtual-try-on .virtual-try-on-description .virtual-try-on-description-item a::after {
  top: 13px;
  transform: rotate(-45deg);
}

.contact-box-start-page {
  display: flex;
  border: 1px solid rgb(226, 226, 226);
  margin: 0;
  min-height: 100vh;
  width: 100vw;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page {
    flex-direction: column-reverse;
  }
}
.contact-box-start-page .contact-box-start-page__content {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 40px 56px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__content {
    width: 100%;
    padding: 32px;
    flex-grow: 1;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__content {
    padding: 16px 24px;
  }
}
.contact-box-start-page .contact-box-start-page__content .content-area {
  background: white;
}
.contact-box-start-page .contact-box-start-page__content .content-area .headline {
  margin-bottom: 12px;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__content .content-area .headline {
    margin-bottom: 4px;
  }
}
.contact-box-start-page .contact-box-start-page__content .content-area .headline h1,
.contact-box-start-page .contact-box-start-page__content .content-area .headline h2,
.contact-box-start-page .contact-box-start-page__content .content-area .headline h3,
.contact-box-start-page .contact-box-start-page__content .content-area .headline h4,
.contact-box-start-page .contact-box-start-page__content .content-area .headline h5,
.contact-box-start-page .contact-box-start-page__content .content-area .headline h6 {
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: rgb(218, 50, 42);
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h1,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h2,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h3,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h4,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h5,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h6 {
    font-size: 40px;
    line-height: 48px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h1,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h2,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h3,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h4,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h5,
  .contact-box-start-page .contact-box-start-page__content .content-area .headline h6 {
    font-size: 36px;
    line-height: 44px;
  }
}
.contact-box-start-page .contact-box-start-page__content .content-area p {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #000;
  margin: 0;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__content .content-area p {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  }
}
.contact-box-start-page .contact-box-start-page__content .content-area .button {
  position: relative;
  margin: 24px 0 0 0;
  padding-right: 60px;
  font-weight: 600;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__content .content-area .button {
    margin: 16px 0 0 0;
  }
}
.contact-box-start-page .contact-box-start-page__content .content-area .button::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  width: 18px;
  height: 16px;
  background-image: url("/assets/img/filled-arrow.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.contact-box-start-page .contact-box-start-page__content .contact-area {
  margin-top: 40px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__content .contact-area {
    margin-top: 32px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__content .contact-area {
    margin-top: 16px;
  }
}
.contact-box-start-page .contact-box-start-page__content .contact-area .headline {
  margin-bottom: 4px;
}
.contact-box-start-page .contact-box-start-page__content .contact-area .headline h1,
.contact-box-start-page .contact-box-start-page__content .contact-area .headline h2,
.contact-box-start-page .contact-box-start-page__content .contact-area .headline h3,
.contact-box-start-page .contact-box-start-page__content .contact-area .headline h4,
.contact-box-start-page .contact-box-start-page__content .contact-area .headline h5,
.contact-box-start-page .contact-box-start-page__content .contact-area .headline h6 {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  color: #000;
}
.contact-box-start-page .contact-box-start-page__content .contact-area p {
  font-size: 18px;
  line-height: 28px;
  color: #000;
  margin-bottom: 16px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__content .contact-area p {
    margin-top: 8px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__content .contact-area p {
    margin-top: 16px;
  }
}
.contact-box-start-page .contact-box-start-page__content .contact-area .contact-details a,
.contact-box-start-page .contact-box-start-page__content .contact-area .contact-details span {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #000;
  text-decoration: none;
  width: max-content;
}
.contact-box-start-page .contact-box-start-page__content .contact-area .contact-details a:hover {
  text-decoration: underline;
}
.contact-box-start-page .contact-box-start-page__image {
  position: relative;
  width: 50%;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__image {
    width: 100%;
    max-height: 400px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__image {
    max-height: 220px;
  }
}
.contact-box-start-page .contact-box-start-page__image .overlay-logo {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 50%;
  height: auto;
  max-width: 216px;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.32));
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__image .overlay-logo {
    max-width: 200px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__image .overlay-logo {
    max-width: 120px;
    top: 16px;
    right: 16px;
  }
}
.contact-box-start-page .contact-box-start-page__image .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-start-page .contact-box-start-page__image .background-image {
    max-height: 400px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-start-page .contact-box-start-page__image .background-image {
    max-height: 220px;
  }
}
.contact-box-start-page.-black {
  background: black;
  border: 1px solid black;
}
.contact-box-start-page.-black .contact-box-start-page__content .content-area {
  background: black;
}
.contact-box-start-page.-black .contact-box-start-page__content .content-area p {
  color: white;
}
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .headline h1,
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .headline h2,
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .headline h3,
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .headline h4,
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .headline h5,
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .headline h6 {
  color: white;
}
.contact-box-start-page.-black .contact-box-start-page__content .contact-area p {
  color: white;
}
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .contact-details a,
.contact-box-start-page.-black .contact-box-start-page__content .contact-area .contact-details span {
  color: white;
}

.contact-box-sub-page {
  position: relative;
  display: flex;
  border: 1px solid rgb(226, 226, 226);
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  padding: 40px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page {
    position: static;
    flex-direction: column;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-sub-page {
    padding: 16px;
  }
}
.contact-box-sub-page .logo {
  position: absolute;
  top: 40px;
  left: 40px;
  height: 41px;
  width: max-content;
  margin-bottom: 32px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page .logo {
    position: static;
    order: 1;
  }
}
.contact-box-sub-page .contact-box-sub-page__content {
  width: calc(40% - 20px);
  margin-right: 20px;
  margin-top: 64px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page .contact-box-sub-page__content {
    width: 100%;
    margin: 0;
    order: 3;
  }
}
.contact-box-sub-page .contact-box-sub-page__content .content-area {
  background: white;
}
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline {
  margin-bottom: 12px;
}
@media screen and (max-width: 43.6875em) {
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline {
    margin-bottom: 4px;
  }
}
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline h1,
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline h2,
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline h3,
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline h4,
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline h5,
.contact-box-sub-page .contact-box-sub-page__content .content-area .headline h6 {
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: rgb(218, 50, 42);
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h1,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h2,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h3,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h4,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h5,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h6 {
    font-size: 40px;
    line-height: 48px;
  }
}
@media screen and (max-width: 43.6875em) {
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h1,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h2,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h3,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h4,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h5,
  .contact-box-sub-page .contact-box-sub-page__content .content-area .headline h6 {
    font-size: 36px;
    line-height: 44px;
  }
}
.contact-box-sub-page .contact-box-sub-page__content .content-area p {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #000;
  margin: 0;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page .contact-box-sub-page__content .content-area p {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  }
}
.contact-box-sub-page .contact-box-sub-page__content .content-area .button {
  position: relative;
  margin: 40px 0 0 0;
  font-weight: 600;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page .contact-box-sub-page__content .content-area .button {
    margin: 32px 0 0 0;
  }
}
.contact-box-sub-page .contact-box-sub-page__image {
  position: relative;
  width: calc(60% - 20px);
  margin-left: 20px;
}
@media screen and (max-width: 63.9375em) {
  .contact-box-sub-page .contact-box-sub-page__image {
    width: 100%;
    margin: 0 0 40px 0;
    order: 2;
  }
}
.contact-box-sub-page .contact-box-sub-page__image .background-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
}
.contact-box-sub-page.-black {
  background: black;
  border: 1px solid black;
}
.contact-box-sub-page.-black .contact-box-sub-page__content .content-area {
  background: black;
}
.contact-box-sub-page.-black .contact-box-sub-page__content .content-area p {
  color: white;
}

.search-container {
  display: flex;
  margin-bottom: 24px;
}
@media screen and (max-width: 43.6875em) {
  .search-container {
    flex-direction: column;
  }
}
.search-container .search-bar {
  color: rgb(64, 64, 64);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-transform: none;
  margin: 0 24px 0 0;
  padding: 15px 14px;
  height: 50px;
  border: 2px solid rgb(220, 220, 220);
}
@media screen and (max-width: 43.6875em) {
  .search-container .search-bar {
    margin: 0 0 16px 0;
  }
}
.search-container .search-button {
  font-size: 22px;
  font-weight: 600;
  line-height: 16px;
  background-color: rgb(218, 50, 42);
  color: rgb(255, 255, 255);
  padding: 17px 24px;
}

.map.map-overlay {
  position: relative;
  cursor: pointer;
}
.map.map-overlay::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/img/googlemaps-image.jpg");
}
.map iframe {
  width: 100%;
  height: 560px;
}
.map .map-info-card {
  position: relative;
  display: none;
  flex-direction: column;
  padding: 50px 16px 24px 16px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  min-width: 263px;
}
.map .map-info-card.show {
  display: flex;
}
.map .map-info-card::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 50%;
  transform: rotate(0deg) translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
  z-index: 1;
}
.map .map-info-card .close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background-image: url("/assets/img/icon-x.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin: 0;
}
.map .map-info-card .title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: rgb(218, 50, 42);
  margin-bottom: 12px;
}
.map .map-info-card span {
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 10px;
  color: rgb(64, 64, 64);
}
.map .map-info-card span:last-of-type {
  margin-bottom: 0;
}
.map .map-info-card ul {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
}
.map .map-info-card ul li {
  position: relative;
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 16px;
  color: rgb(64, 64, 64);
  padding-left: 28px;
}
.map .map-info-card ul li:last-of-type {
  margin-bottom: 0;
}
.map .map-info-card ul li::after {
  position: absolute;
  content: "";
  top: -2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}
.map .map-info-card ul li.icon-house::after {
  background-image: url("/assets/img/icon-house.svg");
}
.map .map-info-card ul li.icon-mail::after {
  background-image: url("/assets/img/icon-envelope.svg");
}
.map .map-info-card ul li.icon-phone::after {
  background-image: url("/assets/img/icon-phone.svg");
}
.map .map-info-card ul li.icon-globe::after {
  background-image: url("/assets/img/icon-globe.svg");
}
.map .map-info-card ul li.icon-globe a {
  font-weight: 400;
  color: rgb(218, 50, 42);
}
.map .map-info-card ul li a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
}
.map .map-info-card ul li a:hover {
  text-decoration: underline;
}