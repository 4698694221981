#footer-main {
  .footer-nav-wrapper {
    background: $black;
    padding: 90px 0 60px;

    @include breakpoint(medium down) {
      padding: 40px 0 20px;
    }

    @include breakpoint(medium down) {
      padding: 0;
    }

    .footer-nav {
      width: 75rem;
      margin: 0 auto;
      padding: 0 20px;
      position: relative;
      @include clearfix;

      @media screen and (max-width: 75rem) {
        width: 100%;
      }

      @include breakpoint(small down) {
        padding: 0;
      }

      ul {
        margin: 0;

        li {
          width: 25%;
          float: left;
          list-style: none;
          margin-bottom: 80px;

          @include breakpoint(medium down) {
            width: 100%;
          }

          @include breakpoint(small down) {
            margin: 0;
          }

          a {
            height: 65px;
            display: block;
            margin-bottom: 10px;
            font: {
              family: $primary-font-family;
              size: 30px;
              weight: 300;
            }
            line-height: 34px;
            color: $white;
            word-wrap: break-word;

            &:before {
              content: '#';
              display: inline;
              color: $primary-color;
              margin-right: 5px;
            }

            @include breakpoint(small down) {
              margin: 0;
              line-height: 60px;
              font-size: 22px;
              height: inherit;
            }

            &:hover {
              color: $primary-color;
            }

            @include breakpoint(medium down) {
              font-size: 22px;
            }

            @include breakpoint(small down) {
              display: block;
              border-bottom: 1px solid $white-trans-40;
              padding: 0 20px;
              margin: 0;
            }
          }

          ul {
            display: block;

            @include breakpoint(small down) {
              display: none;
            }

            li {
              width: inherit;
              float: inherit;
              margin-bottom: 3px;

              a {
                height: inherit;
                margin-bottom: inherit;
                font: {
                  size: 20px;
                  weight: 300;
                }
                line-height: 24px;

                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-bottom-wrapper {
    height: 60px;
    background: $white;

    @include breakpoint(medium down){
      height: auto;
    }

    .footer-bottom {
      width: 75rem;
      height: 60px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      @media screen and (max-width: 75rem) {
        width: 100%;
      }

      @include breakpoint(medium down){
        height: auto;
        padding: 40px 0;
        flex-direction: column;
      }

      .footer-logo {
        img {
          height: 36px;
        }

        @include breakpoint(medium down){
          margin-bottom: 20px;
        }
      }

      .footer-legal-nav {
        @include breakpoint(medium down){
          margin-bottom: 20px;
        }

        ul {
          margin: 0;

          li {
            display: inline;
            margin-right: 30px;
            line-height: inherit;

            @include breakpoint(small down){
              display: block;
              text-align: center;
              margin: 0 0 20px;
            }

            &:last-child {
              margin: 0;
            }

            a {
              font: {
                family: $primary-font-family;
                size: 20px;
              }
              color: $primary-color;
              line-height: inherit;

              &:hover {
                color: $black;
              }

              @include breakpoint(small down){
                text-align: center;
              }
            }
          }
        }
      }

      .footer-copyright {
        font: {
          size: 20px;
          family: $primary-font-family;
        }
        line-height: inherit;
        color: $primary-color;

        @include breakpoint(small down){
          color: $black;
        }
      }
    }
  }
}