.off-canvas {
  background: $black;
  display: none;

  &.is-open {
   display: block;
  }

  &-header-main {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 20px;
    border-bottom: 1px solid $medium-gray;

    .off-canvas {
      /*&-searchinput {
        height: 36px;
        width: auto;
        background: $primary-color;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        &.active {
          display: flex;
        }

        form {
          input {
            height: inherit;
            margin: 0;
            border: 0;
            box-shadow: none;
            background: $primary-color;
            color: $white;
            font-weight: 500;
            padding-top: 7px;

            &::placeholder {
              color: $white;
              font-weight: 500;
            }
          }
        }
      }*/

      &-btn-close {
        display: block;
        padding-right: 36px;
        width: 36px;
        height: 36px;
        background: url("/assets/img/icon_menu_close.svg") no-repeat;
        background-size: cover;

        &:focus {
          border: 0 none;
          outline: none;
        }
      }
    }
  }

  &-nav-main {
    ul {
      margin: 0;
      padding: 0;

      li {
        display: block;
        list-style: none;
        padding: 0;

        a {
          padding: 20px;
          display: block;
          line-height: 26px;
          font: {
            family: $primary-font-family;
            size: 26px;
          }
          color: $white;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid $medium-gray;

          &:before {
            content: '#';
            display: inline;
            color: $primary-color;
            margin-right: 5px;
          }

          &:hover {
            color: $primary-color;
          }
        }

        .active {
          a {
            color: $primary-color;
          }
        }

        ul {
          margin: 0 !important;

          li {
            a {
              font-size: 22px;
              line-height: 22px;
              padding: 12px 20px 12px 37px;
              border: 0 none;

              &:before {
                display: none;
              }
            }

            &:first-child {
              a {
                padding: 20px 20px 5px 37px;
              }
            }

            &:last-child {
              a {
                border-bottom: 1px solid $medium-gray;
                padding: 12px 20px 20px 37px;
              }
            }
          }
        }
      }
    }
  }

  &-nav-second {
    ul {
      margin: 0;
      padding: 0;

      li {
        display: block;
        list-style: none;
        padding: 0;

        a {
          padding: 14px 20px 10px;
          display: block;
          font: {
            family: $primary-font-family;
            size: 20px;
            weight: 500;
          }
          line-height: 20px;
          color: $light-gray;
          text-transform: uppercase;
          transition: all 0.2s;
          border-bottom: 1px solid $white-trans-40;

          &:hover {
            color: $white;
          }
        }

        .active {
          a {
            color: $primary-color;
          }
        }
      }
    }
  }

  &-logo {
    display: flex;
    justify-content: center;
    padding: 10px 0 0;
    opacity: .25;

    img {
      display: block;
      width: 120px;
      height: auto;
    }
  }

  &-copyright {
    display: flex;
    justify-content: center;
    padding: 0 0 10px;
    font: {
      size: 12px;
      weight: 500;
    }
    line-height: inherit;
    color: $white-trans-40;
  }

  .top-bar {
    width: auto;
    margin: 0;
  }
}